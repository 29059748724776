import { Button, DialogActions, DialogContent, DialogContentText, IconButton, Input, TextField, Typography } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState } from "react"
import { pageHeaderStyle } from "../../../components/Helper";

interface companyInfoType {
    companyCodeID: string
    plantID: number
    apiKey: string
}
interface propsType {
    inputId: string
    orderType: 'Sales Order' | 'Quote' | 'Customer Return'
    handleClose: Function
    apiUrl: string
    companyInfo: companyInfoType
}

interface formType{
    comment: string
}

const FeedbackModalComponent = (props: propsType) =>  {
    const [formData, setFormData] = useState<formType>({comment: ""})
    const submitComment = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                //'Authorization': 'Bearer my-token'
            },
            body: JSON.stringify({
                "companyCodeID": props.companyInfo.companyCodeID,
                "plantID": props.companyInfo.plantID,
                "apiKey": props.companyInfo.apiKey,
                "documentType": props.orderType.toLocaleUpperCase(),
                "documentID": props.inputId.split('-')[0].trim(),
                "comment": formData.comment
            })
        };
        fetch(props.apiUrl + "SalesOrderComment", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                },
                (error:any) => {
                    console.log(error)
                }
            )
        props.handleClose()
    }
    return (
    <>
        <MuiDialogTitle style={{display: 'flex', alignItems: 'center'}}>
            <Typography style={pageHeaderStyle} variant="h3" gutterBottom display="inline">
                Comment on {props.orderType} #{props.inputId}
            </Typography>
            {<IconButton onClick={() => {props.handleClose()}} style={{position: "absolute", top: "0", right: "0"}}>
                <CloseIcon />
            </IconButton>}
        </MuiDialogTitle>
        <DialogContent>
            <TextField 
                fullWidth
                multiline
                minRows={2}
                maxRows={5}
                label="Comment"
                id="commentInput" 
                placeholder="Write your comment here" 
                onChange={(e:any)=> setFormData({...formData, comment: e.target.value})}>
                
            </TextField>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {submitComment()}} color="primary">Save</Button>
        </DialogActions>
    </>
    );
}
export default FeedbackModalComponent