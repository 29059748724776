import React from "react";
import async from "../components/Async";
import { Users } from "react-feather";
import '../pages/MasterplanERP.css';
import {
    getDefaultCompanyCodeID,
} from "../components/Helper";

const Default = async(() => import("../pages/dashboards/Default/index"));
const ComingSoon = async(()=> import( "../pages/pages/ComingSoon.tsx"));
const unsyncedHubspotOrders = async(()=> import("../pages/pages/unsyncedHubspotOrders"))

// Sales components
const salesorders = async(() => import("../pages/sales/salesorders"));
const customers = async(() => import("../pages/sales/customers"));
const quotes = async(() => import("../pages/sales/quotes"));
const customerreturns = async(() => import("../pages/sales/customerreturns"));
const deliveryblocks = async(() => import("../pages/sales/deliveryblocks"));

// Purchasing components
const purchaseorders = async(() => import("../pages/purchasing/purchaseorders"));
const suppliers = async(() => import("../pages/purchasing/suppliers"));
const rfqs = async(() => import("../pages/purchasing/requestforquotes"));
const supplierreturns = async(() => import("../pages/purchasing/supplierreturns"));

// Items components
const exceptions = async(() => import("../pages/mrp/exceptions"));
const itemmaster = async(() => import("../pages/items/itemmaster"));
const transferorders = async(() => import("../pages/items/transferorders"));

// Employees components
const employees = async(() => import("../pages/employees/employees"));
const timecards = async(() => import("../pages/employees/timecards"));
const timeallocations = async(() => import("../pages/employees/timeallocations"));
const holidays = async(() => import("../pages/employees/holidays"));

// Manufacturing components
const productionorders = async(() => import("../pages/manufacturing/productionorders"));
const estimating = async(() => import("../pages/manufacturing/estimating"));
const workcenters = async(() => import("../pages/manufacturing/workcenters"));
const batches = async(() => import("../pages/manufacturing/batches"));

// Receivables components
const ARaging = async(() => import("../pages/receivables/aging"));

// Payables components
const APaging = async(() => import("../pages/payables/aging"));

// Quality/Maintenance components
const correctiveactions = async(() => import("../pages/quality/correctiveactions"));
const deviationrequests = async(() => import("../pages/quality/deviationrequests"));
const inspectionholds = async(() => import("../pages/quality/inspectionholds"));
const equipment = async(() => import("../pages/quality/equipment"));
const maintenancerequests = async(() => import("../pages/quality/maintenancerequests"));
const maintenanceorders = async(() => import("../pages/quality/maintenanceorders"));
const nonconformance = async(() => import("../pages/quality/nonconformance"));
const calibrationtools = async(() => import("../pages/quality/calibrationtools"));
const supplierPerformance = async(() => import("../pages/quality/supplierPerformance.tsx"));
const feedbacklist = async(()=> import("../pages/quality/feedbackList.tsx"));

// MRP components
const shortage = async(() => import("../pages/mrp/shortage"));
const recommendations = async(() => import("../pages/mrp/recommendations"));
const messages = async(() => import("../pages/mrp/messages"));
const supplierschedule = async(() => import("../pages/mrp/supplierschedule"));

// Financials components
const accountingperiods = async(() => import("../pages/financials/accountingperiods"));
const budgets = async(() => import("../pages/financials/budgets"));
const chartofaccounts = async(() => import("../pages/financials/chartofaccounts"));
const generalledger = async(() => import("../pages/financials/generalledger"));
const cashflowforecasts = async(() => import("../pages/financials/cashflowforecasts"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// CRM components
const leads = async(() => import("../pages/crm/leads"));
const campaigns = async(() => import("../pages/crm/campaigns"));
const opportunities = async(() => import("../pages/crm/opportunities"));
const tasks = async(() => import("../pages/crm/tasks"));

// System components
const blank = async(() => import("../pages/pages/Blank"));
const search = async(() => import("../pages/pages/search"));

// CustomerPortal components
const portalQuotes = async(() => import("../pages/customerPortal/portalQuotes.tsx"));
const portalSalesOrders = async(() => import ("../pages/customerPortal/portalSalesOrders.tsx"));
const portalCustomerReturns = async(() => import ("../pages/customerPortal/portalCustomerReturns.tsx"));
const portalInvoice = async(() => import("../pages/customerPortal/portalInvoices.tsx"));
const portalShipping = async(() => import ("../pages/customerPortal/portalShipping.tsx"));
const portalCustomerFeedback = async (()=> import ("../pages/customerPortal/portalCustomerFeedback.tsx"));

const dashboardsRoutes = {
    id: "Dashboard",
    path: "/",
    enabled: false,
    component: Default,
    containsHome: true,
    tooltip: "Dashboard",
    subcategory: "Dashboard",
    is24SO: false,
    children: null
};
const unsyncedHubspotOrdersRoute = {
    id: "UnsyncedHubspotOrders",
    path: "/unsynced_hubspot_orders",
    enabled: false,
    tooltip: "misc",
    subcategory:"misc",
    is24SO: false,
    component: unsyncedHubspotOrders,
    children: null
}

const authRoutes = {
    id: "Auth",
    path: "/auth",
    icon: <Users />,
    enabled: false,
    tooltip: "Authentication",
    subcategory: "Auth",
    is24SO: false,
    children: [
        {
            path: "/auth/sign-in",
            name: "Sign In",
            component: SignIn,
            enabled: true
        },
        {
            path: "/auth/sign-up",
            name: "Sign Up",
            component: SignUp,
            enabled: true
        },
        {
            path: "/auth/reset-password",
            name: "Reset Password",
            component: ResetPassword,
            enabled: true
        },
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404,
            enabled: true
        },
        {
            path: "/auth/500",
            name: "500 Page",
            component: Page500,
            enabled: true
        }
    ]
};


const salesRoutes = {
    id: "Sales",
    path: "/sales",
    description: "",
    enabled: false,
    tooltip: "Sales",
    subcategory: "Sales",
    is24SO: false,
    children: [
        {
            path: "/sales_transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "Quotes",
            component: blank,
            tooltip: "Quotes",
            externalpath: "../frmSALES_DataEntry.aspx?OrderType=QUOTE",
            enabled: true
        },
        {
            path: "#",
            name: "Sales Orders",
            component: blank,
            tooltip: "Sales Orders",
            externalpath: "../frmSALES_DataEntry.aspx?OrderType=SALES%20ORDER",
            enabled: true
        },
        {
            path: "#",
            name: "Shipping",
            component: blank,
            tooltip: "Shipping",
            externalpath: "../frmSALES_Shipping.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Customer Returns",
            component: blank,
            tooltip: "Customer Returns",
            externalpath: "../frmSALES_CustomerReturns.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Search History",
            component: blank,
            tooltip: "Search History",
            externalpath: "../frmSALES_Search.aspx",
            enabled: true
        },
        {
            path: "/sales_lists",
            name: "Lists",
            subcategory: true
        },
        {
            path: "/sales_customers",
            name: "Customers",
            component: customers,
            tooltip: "Customers",
            enabled: true
        },
        {
            path: "/sales_deliveryblocks",
            name: "Delivery Blocks",
            component: deliveryblocks,
            tooltip: "Delivery Blocks",
            enabled: true
        },
        {
            path: "/sales_quotes",
            name: "Open Quotes",
            component: quotes,
            tooltip: "Open Quotes",
            enabled: true
        },
        {
            path: "/sales_salesorders",
            name: "Open Sales Orders",
            component: salesorders,
            tooltip: "Open Sales Orders",
            enabled: true
        },
        {
            path: "/sales_customerreturns",
            name: "Open Customer Returns",
            component: customerreturns,
            tooltip: "Open Customer Returns",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Reports",
            categoryid: 1,
            enabled: true
        }
    ]
};

const crmRoutes = {
    id: "CRM",
    path: "/crm",
    description: "",
    enabled: false,
    tooltip: "CRM",
    subcategory: "CRM",
    is24SO: false,
    children: [
        {
            path: "/crm_transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "Leads",
            component: blank,
            tooltip: "Leads",
            externalpath: "../frmCRM_AddLead.aspx?LeadID=-1&TimeStamp=" + new Date().getTime(),
            enabled: true
        },
        {
            path: "#",
            name: "Campaign",
            component: blank,
            tooltip: "Campaign",
            externalpath: "../frmSALES_Marketing.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Opportunities",
            component: blank,
            tooltip: "Opportunities",
            externalpath: "../frmCRM_AddOpportunity.aspx?OpportunityID=-1&TimeStamp=" + new Date().getTime(),
            enabled: true
        },
        {
            path: "#",
            name: "Tasks",
            component: blank,
            tooltip: "Tasks",
            externalpath: "../frmCRM_AddTask.aspx?ContactID=-1&TimeStamp=" + new Date().getTime(),
            enabled: true
        },
        {
            path: "/crm_lists",
            name: "Lists",
            subcategory: true
        },
        {
            path: "/crm_leads",
            name: "Leads",
            component: leads,
            tooltip: "Leads",
            enabled: true
        },
        {
            path: "/crm_campaigns",
            name: "Open Campaigns",
            component: campaigns,
            tooltip: "Open Campaigns",
            enabled: true
        },
        {
            path: "/crm_opportunities",
            name: "Open Opportunities",
            component: opportunities,
            tooltip: "Open Opportunities",
            enabled: true
        },
        {
            path: "/crm_tasks",
            name: "Open Tasks",
            component: tasks,
            tooltip: "Open Tasks",
            enabled: true
        }
    ]
};

const purchasingRoutes = {
    id: "Purchasing",
    path: "/purchasing",
    description: "",
    enabled: false,
    tooltip: "Purchasing",
    subcategory: "Purchasing",
    is24SO: false,
    children: [
        {
            path: "/purchasing_transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "RFQ",
            component: blank,
            tooltip: "RFQ",
            externalpath: "../frmPURCHASING_DataEntry.aspx?OrderType=RFQ",
            enabled: true
        },
        {
            path: "#",
            name: "Purchase Orders",
            component: blank,
            tooltip: "Purchase Orders",
            externalpath: "../frmPURCHASING_DataEntry.aspx?OrderType=PURCHASE%20ORDER",
            enabled: true
        },
        {
            path: "#",
            name: "Goods Receipt",
            component: blank,
            tooltip: "Goods Receipt",
            externalpath: "../frmITEMS_GoodsReceipt.aspx?Type=Goods%20Receipt",
            enabled: true
        },
        {
            path: "#",
            name: "Supplier Return",
            component: blank,
            tooltip: "Supplier Return",
            externalpath: "../frmPURCHASING_SupplierReturns.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Search History",
            component: blank,
            tooltip: "Search History",
            externalpath: "../frmPURCHASING_Search.aspx",
            enabled: true
        },
        {
            path: "/purchasing_lists",
            name: "Lists",
            subcategory: true
        },
        {
            path: "/purchasing_suppliers",
            name: "Suppliers",
            component: suppliers,
            tooltip: "Suppliers",
            enabled: true
        },
        {
            path: "/purchasing_requestforquotes",
            name: "Open RFQs",
            component: rfqs,
            tooltip: "Open RFQs",
            enabled: true
        },
        {
            path: "/purchasing_purchaseorders",
            name: "Open Purchase Orders",
            component: purchaseorders,
            tooltip: "Open Purchase Orders",
            enabled: true
        },
        {
            path: "/purchasing_supplierreturns",
            name: "Open Supplier Returns",
            component: supplierreturns,
            tooltip: "Open Supplier Returns",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Report Center",
            categoryid: 2,
            enabled: true
        }
    ]
};

const manufacturingRoutes = {
    id: "Manufacturing",
    path: "/manufacturing",
    description: "",
    enabled: false,
    tooltip: "Manufacturing",
    subcategory: "Manufacturing",
    is24SO: false,
    children: [
        {
            path: "/manufacturing_transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "Estimating",
            component: blank,
            tooltip: "Estimating",
            externalpath: "../frmMFG_Estimating.aspx?OrderType=ESTIMATING&TransactionID=1",
            enabled: true
        },
        {
            path: "#",
            name: "Production Orders",
            component: blank,
            tooltip: "Production Orders",
            externalpath: "../frmMFG_ProductionOrders.aspx?OrderType=PRODUCTION%20ORDER",
            enabled: true
        },
        {
            path: "#",
            name: "Convert Sales Orders",
            component: blank,
            tooltip: "Convert Sales Orders",
            externalpath: "../frmSALES_ViewPendingSalesOrderReleases.aspx?OrderType=SALES ORDER&OrderID=-1",
            enabled: true
        },
        {
            path: "#",
            name: "Production Schedule",
            component: blank,
            tooltip: "Production Schedule",
            externalpath: "../frmMFG_ProductionScheduling.aspx",
            fullscreen: true,
            enabled: true
        },
        {
            path: "#",
            name: "Search History",
            component: blank,
            tooltip: "Search History",
            externalpath: "../frmMFG_Search.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Batching",
            component: blank,
            tooltip: "Batching",
            externalpath: "../frmMFG_Batching.aspx",
            enabled: true,
            rcCode: "1044_CASIW|59_CASIW"
        },
        {
            path: "/manufacturing_Lists",
            name: "Lists",
            subcategory: true
        },
        {
            path: "/manufacturing_workcenters",
            name: "Work Centers",
            component: workcenters,
            tooltip: "Work Centers",
            enabled: true
        },
        {
            path: "/manufacturing_productionorders",
            name: "Open Production Orders",
            component: productionorders,
            tooltip: "Open Production Orders",
            enabled: true
        },
        {
            path: "/manufacturing_estimates",
            name: "Open Estimates",
            component: estimating,
            tooltip: "Open Estimates",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Report Center",
            categoryid: 3,
            enabled: true
        },
        {
            path: "/manufacturing_batches",
            name: "Batches",
            component: batches,
            tooltip: "Open Batches",
            enabled: true,
            rcCode: "1044_CASIW|59_CASIW"
        }
    ]
};

const itemRoutes = {
    id: "Items",
    path: "/items",
    description: "",
    enabled: false,
    tooltip: "Items",
    subcategory: "Items",
    is24SO: false,
    children: [
        {
            path: "/items_Transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "Item Master",
            component: blank,
            tooltip: "Item Master",
            externalpath: "../frmITEMS_ItemMaster.aspx?TimeStamp=" + Date.now(),
            enabled: true
        },
        {
            path: "#",
            name: "Cycle Count",
            component: blank,
            tooltip: "Cycle Count",
            externalpath: "../frmITEMS_CycleCount.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Transfer Order",
            component: blank,
            tooltip: "Transfer Order",
            externalpath: "../frmITEMS_TransferOrder.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Traceability",
            component: blank,
            tooltip: "Traceability",
            externalpath: "../frmITEMS_Traceability.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Item Inquiry",
            component: blank,
            tooltip: "Item Inquiry",
            externalpath: "../frmITEMS_ItemInquiry.aspx",
            enabled: true
        },
        {
            path: "/items_Lists",
            name: "Lists",
            subcategory: true
        },
        {
            path: "/items_itemlist",
            name: "Item List",
            component: itemmaster,
            tooltip: "Item List",
            enabled: true,
            is24SO: false
        },
        {
            path: "/items_exceptions",
            name: "Exceptions",
            component: exceptions,
            tooltip: "Exceptions",
            enabled: true
        },
        {
            path: "/items_transferorders",
            name: "Open Transfer Orders",
            component: transferorders,
            tooltip: "Open Transfer Orders",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Report Center",
            categoryid: 4,
            enabled: true
        }
    ]
};

const mrpRoutes = {
    id: "MRP",
    path: "/mrp",
    description: "",
    enabled: false,
    tooltip: "MRP",
    subcategory: "MRP",
    is24SO: false,
    children: [
        {
            path: "/mrp_Transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "Forecast",
            component: blank,
            tooltip: "Forecast",
            externalpath: "../frmMRP_Forecast.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Pegged Shortage",
            component: blank,
            tooltip: "Pegged Shortage",
            externalpath: "../frmSYSTEM_AccessDenied.aspx",
            enabled: true
        },
        {
            path: "/mrp_supplierschedule",
            name: "Supplier Schedule",
            component: supplierschedule,
            tooltip: "Supplier Schedule",
            //externalpath: "../frmSYSTEM_AccessDenied.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Workbench",
            component: blank,
            tooltip: "Workbench",
            externalpath: "../frmMRP_Workbench.aspx",
            fullscreen: true,
            enabled: true
        },
        {
            path: "/mrp_Lists",
            name: "Lists",
            subcategory: true
        },
        {
            path: "/mrp_messages",
            name: "Messages",
            component: messages,
            tooltip: "MRP Messages",
            enabled: true
        },
        {
            path: "/mrp_recommendations",
            name: "Recommendations",
            component: recommendations,
            tooltip: "MRP Recommendations",
            enabled: true
        },
        {
            path: "/mrp_shortage",
            name: "Shortage",
            component: shortage,
            tooltip: "MRP Shortage",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Report Center",
            categoryid: 11,
            enabled: true
        }
    ]
};

const employeeRoutes = {
    id: "Employees",
    path: "/employees",
    description: "",
    enabled: false,
    tooltip: "Employees",
    subcategory: "Employees",
    is24SO: false,
    children: [
        {
            path: "/employees_Transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "Employee",
            component: blank,
            tooltip: "Employee",
            externalpath: "../frmHR_Employees.aspx?EmployeeID=-1&TimeStamp=" + Date.now(),
            enabled: true
        },
        {
            path: "#",
            name: "Organizational Chart",
            component: blank,
            tooltip: "Organizational Chart",
            externalpath: "../frmHR_OrganizationalChart.aspx",
            enabled: true
        },
        {
            path: "/employees_List",
            name: "List",
            subcategory: true
        },
        {
            path: "/employees_employees",
            name: "Employee List",
            component: employees,
            tooltip: "Employee List",
            enabled: true
        },
        {
            path: "/employees_holidays",
            name: "Holidays",
            component: holidays,
            tooltip: "Holidays",
            enabled: true
        },
        {
            path: "/employees_timecards",
            name: "Time Cards",
            component: timecards,
            tooltip: "Time Cards",
            enabled: true
        },
        {
            path: "/employees_timeallocations",
            name: "Time Allocations",
            component: timeallocations,
            tooltip: "Time Allocations",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Report Center",
            categoryid: 5,
            enabled: true
        }
    ]
};

const qualityRoutes = {
    id: "Quality",
    path: "/quality",
    description: "",
    enabled: false,
    tooltip: "Quality",
    subcategory: "Quality",
    is24SO: false,
    children: [
        {
            path: "/quality_Transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "Corrective Action",
            component: blank,
            tooltip: "Corrective Action",
            externalpath: "../frmQC_CAPA.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Deviation Request",
            component: blank,
            tooltip: "Deviation Request",
            externalpath: "../frmQC_DeviationRequest.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "New Feedback",
            component: blank,
            tooltip: "New Feedback",
            externalpath: "../frmQA_AddFeedback.aspx?FeedbackID=-1&TimeStamp=" + Date.now(),
            enabled: true
        },
        {
            path: "#",
            name: "Non-Conformance",
            component: blank,
            tooltip: "Non-Conformance",
            externalpath: "../frmQC_NonConformanceReport.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Tool Calibration",
            component: blank,
            tooltip: "Tool Calibration",
            externalpath: "../frmQC_CalibrateTool.aspx",
            enabled: true
        },
        {
            path: "/quality_Lists",
            name: "Lists",
            subcategory: true
        },
        {
            path: "/quality_calibrationtools",
            name: "Calibration Tools",
            component: calibrationtools,
            tooltip: "Calibration Tools",
            enabled: true
        },
        {
            path: "/quality_feedbacklist",
            name: "Feedback List",
            component: feedbacklist,
            tooltip: "Feedback List",
            enabled: true
        },
        {
            path: "#",
            name: "Quality Documents",
            component: blank,
            tooltip: "Quality Documents",
            externalpath: "../frmQC_ISODocuments.aspx",
            enabled: true
        },
        {
            path: "/quality_inspectionholds",
            name: "Inspection Holds",
            component: inspectionholds,
            tooltip: "Inspection Holds",
            enabled: true
        },
        {
            path: "/quality_correctiveactions",
            name: "Open Corrective Actions",
            component: correctiveactions,
            tooltip: "Open Corrective Actions",
            enabled: true
        },
        {
            path: "/quality_deviationrequests",
            name: "Open Deviation Requests",
            component: deviationrequests,
            tooltip: "Open Deviation Requests",
            enabled: true
        },
        {
            path: "/quality_nonconformances",
            name: "Open Non-Conformances",
            component: nonconformance,
            tooltip: "Open Non-Conformances",
            enabled: true
        },
        {
            path: "/quality_supplierperformance",
            name: "Supplier Performance",
            component: supplierPerformance,
            tooltip: "Supplier Performance",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Report Center",
            categoryid: 6,
            enabled: true
        }

    ]
};

const maintenanceRoutes = {
    id: "Maintenance",
    path: "/maintenance",
    description: "",
    enabled: false,
    tooltip: "Maintenance",
    subcategory: "Quality",
    is24SO: false,
    children: [
        {
            path: "/maintenance_Transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "Equipment",
            component: blank,
            tooltip: "Equipment",
            externalpath: "../frmPM_AddEquipment.aspx?EquipmentID=-1&TimeStamp=" + new Date().getTime(),
            enabled: true
        },
        {
            path: "#",
            name: "Maintenance Order",
            component: blank,
            tooltip: "Maintenance Order",
            externalpath: "../frmMFG_ProductionOrders.aspx?OrderType=PRODUCTION%20ORDER&MaintenanceOrder=TRUE",
            enabled: true
        },
        {
            path: "#",
            name: "Maintenance Request",
            component: blank,
            tooltip: "Maintenance Request",
            externalpath: "../frmPM_AddMaintenanceRequest.aspx?MaintenanceRequestID=-1&TimeStamp=" + new Date().getTime(),
            enabled: true
        },
        {
            path: "/maintenance_Lists",
            name: "Lists",
            subcategory: true
        },
        {
            path: "/maintenance_equipment",
            name: "Equipment List",
            component: equipment,
            tooltip: "Equipment List",
            enabled: true
        },
        {
            path: "/maintenance_maintenanceorders",
            name: "Open Orders",
            component: maintenanceorders,
            tooltip: "Open Maintenance Orders",
            enabled: true
        },
        {
            path: "/maintenance_maintenancerequests",
            name: "Open Requests",
            component: maintenancerequests,
            tooltip: "Open Maintenance Requests",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Report Center",
            categoryid: 6,
            enabled: true
        }
    ]
};

const financialsRoutes = {
    id: "Financials",
    path: "/financials",
    description: "",
    enabled: false,
    tooltip: "Financials",
    subcategory: "Financials",
    is24SO: false,
    children: [
        {
            path: "/financials_Transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "Deposit",
            component: blank,
            tooltip: "Deposit",
            externalpath: "../frmACCT_BankDeposit.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Financial Statements",
            component: blank,
            tooltip: "Financial Statements",
            externalpath: "../frmACCT_FinancialStatements.aspx",
            fullscreen: true,
            enabled: true
        },
        {
            path: "#",
            name: "Journal Entry",
            component: blank,
            tooltip: "Journal Entry",
            externalpath: "../frmACCT_JournalEntry.aspx?CompanyCodeID=" + sessionStorage.getItem('default_companycode') || getDefaultCompanyCodeID() + "&Timestamp=" + new Date().getTime(),
            enabled: true
        },
        {
            path: "#",
            name: "Reconcile",
            component: blank,
            tooltip: "Reconcile",
            externalpath: "../frmACCT_BankReconcile.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Register",
            component: blank,
            tooltip: "Register",
            externalpath: "../frmACCT_Register.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Import Transactions",
            component: blank,
            tooltip: "Import Transactions",
            externalpath: "../frmACCT_BankImport.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Standard Cost Workbench",
            component: blank,
            tooltip: "Standard Cost Workbench",
            externalpath: "../frmACCT_StandardCostWorkbench.aspx",
            enabled: true
        },
        {
            path: "/financials_Lists",
            name: "Lists",
            subcategory: true
        },
        {
            path: "/financials_cashflowforecasts",
            name: "Cash Flow Forecast",
            component: cashflowforecasts,
            tooltip: "Cash Flow Forecast",
            /*            externalpath: "../frmACCT_CashFlowForecast.aspx",*/
            enabled: true
        },
        {
            path: "/financials_accountingperiods",
            name: "Accounting Periods",
            component: accountingperiods,
            tooltip: "Accounting Periods",
            enabled: true
        },
        {
            path: "/financials_budgets",
            name: "Budgets",
            component: budgets,
            tooltip: "Budgets",
            enabled: true
        },
        {
            path: "/financials_chartofaccounts",
            name: "Chart of Accounts",
            component: chartofaccounts,
            tooltip: "Chart of Accounts",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Report Center",
            categoryid: 7,
            enabled: true
        }
    ]
};

const receivablesRoutes = {
    id: "Receivables",
    path: "/receivables",
    description: "",
    enabled: false,
    tooltip: "Receivables",
    subcategory: "Receivables",
    is24SO: false,
    children: [
        {
            path: "/financials_Transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "Customer Invoice",
            component: blank,
            tooltip: "Customer Invoice",
            externalpath: "../frmSALES_Invoice.aspx?OrderType=Invoice",
            enabled: true
        },
        {
            path: "#",
            name: "Customer Credit",
            component: blank,
            tooltip: "Customer Credit",
            externalpath: "../frmSALES_Invoice.aspx?OrderType=Credit",
            enabled: true
        },
        {
            path: "#",
            name: "Receive Payment",
            component: blank,
            tooltip: "Receive Payment",
            externalpath: "../frmACCT_ReceivePayment.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Search History",
            component: blank,
            tooltip: "Search History",
            externalpath: "../frmACCT_ReceivablesSearch.aspx",
            enabled: true
        },
        {
            path: "/financials_Lists",
            name: "Lists",
            subcategory: true
        },
        {
            path: "/receivables_aging",
            name: "A/R Aging",
            component: ARaging,
            tooltip: "A/R Aging",
            enabled: true
        },
        {
            path: "#",
            name: "Customer Analysis",
            component: blank,
            tooltip: "Customer Analysis",
            externalpath: "../frmACCT_IncomeExpenseAnalysis.aspx?myType=AR",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Report Center",
            categoryid: 8,
            enabled: true
        }
    ]
};

const payablesRoutes = {
    id: "Payables",
    path: "/payables",
    description: "",
    enabled: false,
    tooltip: "Payables",
    subcategory: "Payables",
    is24SO: false,
    children: [
        {
            path: "/financials_Transactions",
            name: "Transactions",
            subcategory: true
        },
        {
            path: "#",
            name: "Supplier Invoice",
            component: blank,
            tooltip: "Supplier Invoice",
            externalpath: "../frmACCT_SupplierInvoices.aspx?OrderType=Invoice",
            enabled: true
        },
        {
            path: "#",
            name: "Supplier Credit",
            component: blank,
            tooltip: "Supplier Credit",
            externalpath: "../frmACCT_SupplierInvoices.aspx?OrderType=Credit",
            enabled: true
        },
        {
            path: "#",
            name: "Pay Suppliers",
            component: blank,
            tooltip: "Pay Suppliers",
            externalpath: "../frmACCT_PayVendors.aspx",
            fullscreen: false,
            enabled: true
        },
        {
            path: "#",
            name: "Search History",
            component: blank,
            tooltip: "Search History",
            externalpath: "../frmACCT_PayablesSearch.aspx",
            enabled: true
        },
        {
            path: "/financials_Lists",
            name: "Lists",
            subcategory: true
        },
        {
            path: "/payables_aging",
            name: "A/P Aging",
            component: APaging,
            tooltip: "A/P Aging",
            enabled: true
        },
        {
            path: "#",
            name: "Supplier Analysis",
            component: blank,
            tooltip: "Supplier Analysis",
            externalpath: "../frmACCT_IncomeExpenseAnalysis.aspx?myType=AP",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Report Center",
            categoryid: 9,
            enabled: true
        }
    ]
};

const integrationsRoutes = {
    id: "Integrations",
    path: "/integrations",
    description: "",
    enabled: false,
    tooltip: "Integrations",
    subcategory: "Integrations",
    is24SO: false,
    children: [
        {
            path: "#",
            name: "24SevenOffice",
            component: blank,
            tooltip: "24SevenOffice",
            externalpath: "../frmINTEGRATIONS_24SOConfig.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Amazon",
            component: blank,
            tooltip: "Amazon",
            externalpath: "../frmINTEGRATIONS_Amazon.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Big Commerce",
            component: blank,
            tooltip: "Big Commerce",
            externalpath: "../frmINTEGRATIONS_BigCommerce.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "QuickBooks Online",
            component: blank,
            tooltip: "QuickBooks Online",
            externalpath: "../frmINTEGRATIONS_QBO.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Shopify",
            component: blank,
            tooltip: "Shopify",
            externalpath: "../frmINTEGRATIONS_Shopify.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Tallie",
            component: blank,
            tooltip: "Tallie",
            externalpath: "../frmINTEGRATIONS_Tallie.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Walmart",
            component: blank,
            tooltip: "Walmart",
            externalpath: "../frmINTEGRATIONS_Walmart.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Woo Commerce",
            component: blank,
            tooltip: "Woo Commerce",
            externalpath: "../frmINTEGRATIONS_WooCommerce.aspx",
            enabled: true
        },
        {
            path:"#",
            name: "Hubspot",
            component: blank,
            tooltip: "Hubspot",
            externalpath: "../frmINTEGRATIONS_Hubspot.aspx",
            enabled:true
        }
    ]
};

const inventoryEditionRoutes = {
    id: "Inventory Edition",
    path: "/inventoryedition",
    description: "",
    enabled: false,
    tooltip: "Inventory Edition",
    subcategory: "InventoryEdition",
    is24SO: false,
    children: [
        {
            path: "/inventoryedition_salesorders",
            name: "Sales Orders",
            component: salesorders,
            tooltip: "Sales Orders",
            enabled: true
        },
        {
            path: "/inventoryedition_purchaseorders",
            name: "Purchase Orders",
            component: purchaseorders,
            tooltip: "Purchase Orders",
            enabled: true
        },
        {
            path: "#",
            name: "MRP Workbench",
            component: blank,
            tooltip: "MRP Workbench",
            externalpath: "../frmMRP_Workbench.aspx",
            fullscreen: true,
            enabled: true
        },
        {
            path: "#",
            name: "Traceability",
            component: blank,
            tooltip: "Traceability",
            externalpath: "../frmITEMS_Traceability.aspx",
            enabled: true
        },
        {
            path: "/inventoryedition_Transactions",
            name: "",
            subcategory: true
        },
        {
            path: "/inventoryedition_itemlist",
            name: "Items",
            component: itemmaster,
            tooltip: "Items",
            enabled: true,
            is24SO: false
        },
        {
            path: "/inventoryedition_customers",
            name: "Customers",
            component: customers,
            tooltip: "Customers",
            enabled: true
        },
        {
            path: "/inventoryedition_suppliers",
            name: "Suppliers",
            component: suppliers,
            tooltip: "Suppliers",
            enabled: true
        },
        {
            path: "/inventoryedition_employees",
            name: "Employees",
            component: employees,
            tooltip: "Employees",
            enabled: true
        },
        {
            path: "#",
            name: "Report Center",
            component: blank,
            tooltip: "Reports",
            categoryid: 4,
            enabled: true
        }
    ]
};

const psRoutes = {
    id: "Professional Services",
    path: "/professionalservices",
    description: "",
    enabled: false,
    tooltip: "Professional Services",
    subcategory: "ProfessionalServices",
    is24SO: false,
    children: [
        {
            path: "#",
            name: "Table Maintenance",
            component: blank,
            tooltip: "Table Maintenance",
            externalpath: "../frmSYSTEM_ClearTables.aspx",
            enabled: true
        },
        {
            path: "#",
            name: "Product Configurator",
            component: blank,
            tooltip: "Product Configurator",
            externalpath: "../frmSALES_ProductConfiguratorSetup.aspx",
            enabled: true
        },
    ]
};

const systemRoutes = {
    id: "System",
    path: "/system",
    description: "",
    enabled: false,
    tooltip: "System",
    subcategory: "System",
    is24SO: false,
    children: [
        {
            path: "/system_search",
            name: "Search",
            component: search,
            tooltip: "Search",
            enabled: true
        }
    ]
};

const customerportal = {
    id:"Customer Portal",
    path: "/customerportal",
    description: "",
    enabled: false, //change this to false before release.  Only true for debug.
    tooltip: "Customer Portal",
    subcategory: "CustomerPortal",
    is24SO: false,
    isCustomerPortal: true,
    children: [
        {
            path:"/customerportal_quotes",
            name: "Quotes",
            component: portalQuotes,
            tooltip: "quotes",
            enabled: true
        },
        {
            path:"/customerportal_salesorders",
            name: "Sales Orders",
            component: portalSalesOrders,
            tooltip: "sales orders",
            enabled: true
        },
        {
            path: "/customerportal_shipping",
            name: "Shipping",
            component: portalShipping,
            tooltip: "shipping",
            externalpath: "",
            enabled: true
        },
        {
            path: "/customerportal_customerreturns",
            name: "Customer Returns",
            component: portalCustomerReturns,
            tooltip: "Customer returns",
            enabled: true
        },
        {
            path: "/customerportal_invoices",
            name: "Invoices",
            component: portalInvoice,
            tooltip: "Invoices",
            enabled: true
        },
        {
            path: "/customerportal_customerfeedback",
            name: "Customer Feedback",
            component: portalCustomerFeedback,
            tooltip: "Customer Feedback",
            enabled: true
        }
    ]
}

export const dashboard = [
    dashboardsRoutes,
    salesRoutes,
    crmRoutes,
    purchasingRoutes,
    manufacturingRoutes,
    itemRoutes,
    mrpRoutes,
    employeeRoutes,
    qualityRoutes,
    maintenanceRoutes,
    financialsRoutes,
    receivablesRoutes,
    payablesRoutes,
    inventoryEditionRoutes,
    integrationsRoutes,
    psRoutes,
    systemRoutes,
    customerportal,
    unsyncedHubspotOrdersRoute
];

export const auth = [authRoutes];

export default [
    dashboardsRoutes,
    salesRoutes,
    crmRoutes,
    purchasingRoutes,
    manufacturingRoutes,
    itemRoutes,
    mrpRoutes,
    employeeRoutes,
    qualityRoutes,
    maintenanceRoutes,
    financialsRoutes,
    receivablesRoutes,
    payablesRoutes,
    inventoryEditionRoutes,
    integrationsRoutes,
    psRoutes,
    systemRoutes,
    customerportal
];
