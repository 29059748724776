import React from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import '../pages/MasterplanERP.css';
import {
    Select as MuiSelect,
    MenuItem,
    Grid,
    Typography,
    Button as MuiButton,
    CircularProgress as MuiCircularProgress,
    TableHead,
    TableRow,
    TableCell,
    Tooltip,
    TableSortLabel,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Switch,
    InputBase,
    Badge,
    Chip
} from "@material-ui/core";

import {
    Settings as SettingsIcon,
    Filter as SearchIcon,
} from "react-feather";

import { Loop as LoopIcon } from "@material-ui/icons";

import { darken } from "polished";
import PageHeaderTitleComponent from "./PageHeaderTitle";

/****************/
/*** CONSTANTS ***/
/****************/

const Select = styled(MuiSelect)(spacing);

const Button = styled(MuiButton)(spacing);

const CircularProgress = styled(MuiCircularProgress)(spacing);

/****************/
/*** GET USER INFO ***/
/****************/

var getUserInfo = function () {
    // write cookies
    // Cookies need to be accessible in script (i.e. HttpOnly not set)

    //COMMENT FOR PRODUCTION
    //document.cookie = 'ReactUser={"displayname":"Andrew","companycodes":"1:01 - Demo Company, Inc.|4:2000 - AMI|2:22 - XYZ Division|3:1000 - AMP","apikey":"fxGrz7hiEWhPhLX9yBMYp5kQ12+8vZbzFu3nTAPkfB4=", "apiurl":"https://localhost:44394/api/"}';
    //document.cookie = 'ReactUser={"displayname":"Nali Thephavong","title":"Top Dog","companycodes":"1:1000 - Nali Sandbox & Co.|2:2000 - Nali Canada","plants":"1:1:Nali Sandbox","showportal":"89","apikey":"49rxFvHgbVAKdaaeNuA7H51Eb4CiL3yztLhpFsD/Yb8=", "apiurl":"https://localhost:44394/api/"}';
    //userinfo={"displayname":"JIM","companycodes":"1:01 - Demo Company, Inc.|4:2000 - AMI|2:22 - XYZ Division|3:1000 - AMP","apikey":"fxGrz7hiEWhPhLX9yBMYp5kQ12+8vZbzFu3nTAPkfB4="}
    // read cookies

    var arrayOfCookies = [];
    var myValue = "";

    var cookieArray = document.cookie.split(";");
    cookieArray.map(function (originalValue) {
        originalValue = originalValue.trim();
        if (originalValue.indexOf("ReactUser") == 0) {
            myValue = originalValue;
            myValue = myValue.replace("ReactUser=", "")
            myValue = myValue.replace("userinfo=", "")
            arrayOfCookies['userinfo'] = myValue;
            return true;
        }
    });

    if (myValue != "") {
        checkLoginStatus(JSON.parse(myValue));
        return JSON.parse(myValue);
    }
    else {
        window.location.href = '../../frmLogOut.aspx';
    }
};

var getApiURL = function () {
    var arrayOfCookies = [];
    var myValue = "";

    var cookieArray = document.cookie.split(";");
    cookieArray.map(function (originalValue) {
        originalValue = originalValue.trim();
        if (originalValue.indexOf("ReactUser") == 0) {

            myValue = originalValue;
            myValue = myValue.replace("ReactUser=", "")
            myValue = myValue.replace("userinfo=", "")

            arrayOfCookies['userinfo'] = myValue;
        }
    });

    if (myValue != "") {
        var myJSON = JSON.parse(arrayOfCookies['userinfo']);
        var retval = myJSON.apiurl;

        return retval;
    }
};

var getApiKey = function () {
    var arrayOfCookies = [];
    var myValue = "";

    var cookieArray = document.cookie.split(";");
    cookieArray.map(function (originalValue) {
        originalValue = originalValue.trim();
        if (originalValue.indexOf("ReactUser") == 0) {

            myValue = originalValue;
            myValue = myValue.replace("ReactUser=", "")
            myValue = myValue.replace("userinfo=", "")

            arrayOfCookies['userinfo'] = myValue;
        }
    });

    if (myValue != "") {
        var myJSON = JSON.parse(arrayOfCookies['userinfo']);
        var retval = myJSON.apikey;

        return retval;
    }
};

var getCompanyCodes = function (userInfo) {
    var arrayOfCCs = [];
    var localUserInfo = userInfo
    if (!localUserInfo) {
        localUserInfo = getUserInfo()
    }
    if (localUserInfo !== undefined) {
        var companycodes = localUserInfo.companycodes;
        var ccArray = companycodes.split("|");
        ccArray.map(function (originalValue) {
            var ccItem = {};
            var value = originalValue.split(':')[0];
            var name = originalValue.split(':')[1];
            ccItem.name = name;
            ccItem.value = value;
            arrayOfCCs.push(ccItem);
        });
    }
    return arrayOfCCs;
};

var getCompanyCodeName = function (id) {
    var arr = getCompanyCodes();
    for (var i = 0; i < arr.length; i++)
        if (arr[i].value == id)
            return arr[i].name;
};

var getDefaultCompanyCodeID = function (userInfo) {
    var retvalue = -1;
    var localUserInfo = userInfo
    if (!localUserInfo) {
        localUserInfo = getUserInfo();
    }
    if (localUserInfo !== undefined) {
        if (localUserInfo.defaultcompanycodeid != undefined) {
            retvalue = localUserInfo.defaultcompanycodeid;
        }
    }

    if (retvalue == undefined || retvalue == -1) {
        var arr = getCompanyCodes();
        if (arr.length > 0) {
            retvalue = arr[0].value;
        }
        else {
            retvalue = -1;
        }
    }

    return retvalue;
};
var getSelectedCompanyCodeID = function(userInfo){
    var companyCode = sessionStorage.getItem('default_companycode')
    if (!companyCode) {
        companyCode = getDefaultCompanyCodeID(userInfo)
    }
    return companyCode
}

var getPlants = function (companycodeid, userInfo) {
    var arrayOfPlants = [];
    var localUserInfo = userInfo
    if (!localUserInfo) {
        localUserInfo = getUserInfo()
    }
    if (localUserInfo !== undefined) {
        var plants = localUserInfo.plants;
        if (plants !== undefined) {
            var plantArray = plants.split("|");
            plantArray.map(function (originalValue) {
                if (originalValue.split(':')[0] == companycodeid) {
                    var plantItem = {};
                    var value = originalValue.split(':')[1];
                    var name = originalValue.split(':')[2];
                    plantItem.name = name;
                    plantItem.value = value;
                    arrayOfPlants.push(plantItem);
                }
            });
        }
    }

    if (arrayOfPlants.length > 1) {
        // Add Default All Plants Menu Item
        var plantItem = {};
        plantItem.name = "All Plants";
        plantItem.value = -1;
        arrayOfPlants.unshift(plantItem);
    }

    return arrayOfPlants;
};

var getPlantName = function (id, companycodeid) {
    var arr = getPlants(companycodeid);
    for (var i = 0; i < arr.length; i++)
        if (arr[i].value == id)
            return arr[i].name;
};

var getDefaultPlantID = function (companycodeid, userInfo) {
    var retvalue = -1;

    // Set Plant from Company Code
    if (companycodeid === undefined) {
        companycodeid = Number(getSelectedCompanyCodeID(userInfo));
    }

    var plantsArray = getPlants(companycodeid, userInfo)
    if (plantsArray.length == 1) {
        retvalue = plantsArray[0].value
    }
    else if (plantsArray.length > 1) {
        retvalue = plantsArray[1].value
    }

    // Set Plant from Employee default
    var localUserInfo = userInfo;
    if (!localUserInfo) {
        localUserInfo = getUserInfo();
    }
    if (localUserInfo !== undefined) {
        if (localUserInfo.defaultplantid != undefined) {
            // Check if plant in selected companycode
            if (isPlantInCompanyCode(companycodeid, localUserInfo.defaultplantid, userInfo)) {
                retvalue = localUserInfo.defaultplantid;
            }
        }
    }

    return retvalue;
};

var isPlantInCompanyCode = function (companycodeid, plantid, userInfo) {
    var retvalue = false;
    if (isNumeric(companycodeid) && isNumeric(plantid)) {
        var arr = getPlants(companycodeid, userInfo);
        if (arr.length > 0) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].value == plantid) {
                    retvalue = true;
                    break;
                }
            }
        }
    }

    return retvalue;
};

var checkLoginStatus = function (userinfo) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'Text'
        }
    };

    var apikey = encodeComponent(getApiKey());
    var url = "../frmSYSTEM_Ping.aspx?APIKey=" + apikey;
    var logouturl = '../../frmLogOut.aspx';
    if (userinfo.apiurl.indexOf("localhost") != -1) {
        url = url.replace("..", "https://localhost:44306");
        logouturl = logouturl.replace("../..", "https://localhost:44306");
        return true;
    }

    fetch(url, requestOptions)
        .then(res => res.text())
        .then(
            (result) => {
                if (result.toString().indexOf("TRUE") == 0) {
                    return true;
                }
                else {
                    window.location.href = logouturl;
                }
            },
            (error) => {
                window.location.href = logouturl;
            }
        )
};

var encodeComponent = function (value) {
    return encodeURIComponent(value);
};

/****************/
/*** TABLE SORT ***/
/****************/

function desc(a, b, orderBy) {
    var comparea = a[orderBy];
    if (comparea != "" && comparea != null) {
        if (typeof comparea === 'string' || comparea instanceof String) {
            comparea = a[orderBy].toLowerCase();
        }
    }
    else {
        comparea = "";
    }

    var compareb = b[orderBy];
    if (compareb != "" && compareb != null) {
        if (typeof compareb === 'string' || compareb instanceof String) {
            compareb = b[orderBy].toLowerCase();
        }
    }
    else {
        compareb = "";
    }

    if (comparea != null && (typeof comparea === 'string' || comparea instanceof String) && comparea.indexOf("<") != -1) {
        comparea = get_htmlcontent(comparea);
    }

    if (compareb != null && (typeof compareb === 'string' || compareb instanceof String) && compareb.indexOf("<") != -1) {
        compareb = get_htmlcontent(compareb);
    }

    //writeDebugLines("A: " + comparea + "|B: " + compareb);

    if (orderBy.toLowerCase().indexOf("date") != -1) {
        // Convert to Acceptable Short Date
        if (comparea != null) {
            if (typeof comparea === 'string' || comparea instanceof String) {
                if (Date.parse(comparea)) {
                    comparea = new Date(Date.parse(comparea)).toLocaleDateString('en-us',{year:"numeric", month:"2-digit", day:"2-digit"})
                }
                //fallback in case we can't parse
                else if (comparea.indexOf("-") != -1) {
                    var dateParts = comparea.split("-");
                    if (isNaN(dateParts[1])) {
                        dateParts[1] = getMonthFromString(dateParts[1]);
                    }
                    var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                    comparea = dateObject.toString();
                }
                else if (comparea.indexOf(".") != -1) {
                    var dateParts = comparea.split(".");
                    var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                    comparea = dateObject.toString();
                }
            }
        }

        if (compareb != null) {
            if (typeof compareb === 'string' || compareb instanceof String) {
                if (Date.parse(compareb)) {
                    compareb = new Date(Date.parse(compareb)).toLocaleDateString('en-us',{year:"numeric", month:"2-digit", day:"2-digit"})
                }
                else if (compareb.indexOf("-") != -1) {
                    var dateParts = compareb.split("-");
                    if (isNaN(dateParts[1])) {
                        dateParts[1] = getMonthFromString(dateParts[1]);
                    }
                    var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                    compareb = dateObject.toString();
                }
                else if (compareb.indexOf(".") != -1) {
                    var dateParts = compareb.split(".");
                    var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                    compareb = dateObject.toString();
                }
            }
        }

        if (Date.parse(comparea) != NaN) {
            comparea = Date.parse(comparea);
        }

        if (Date.parse(compareb) != NaN) {
            compareb = Date.parse(compareb);
        }
    } else {
        var newComparea = comparea;
        if (comparea != null && (typeof comparea === 'string' || comparea instanceof String)) {
            if (isUSNumber(comparea)) {
                newComparea = comparea.replace(/,/g, "");
            } else {
                newComparea = comparea.replace(/\s/g, "");
            }
        }

        var newCompareb = compareb;
        if (compareb != null && (typeof compareb === 'string' || compareb instanceof String)) {
            if (isUSNumber(compareb)) {
                newCompareb = compareb.replace(/,/g, "");
            } else {
                newCompareb = compareb.replace(/\s/g, "");
            }
        }

        if (!isNaN(parseFloat(newCompareb)) && !isNaN(parseFloat(newComparea))) {
            comparea = parseFloat(newComparea)
            compareb = parseFloat(newCompareb)
        }
    }

    if (compareb < comparea) {
        return -1;
    }
    if (compareb > comparea) {
        return 1;
    }
    return 0;
}

var stableSort = function (array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

var getSorting = function (order, orderBy) {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function isUSNumber(value) {
    var retval = true;
    if (value != "" && value != null && (typeof value === 'string' || value instanceof String)) {
        if (value.indexOf(".") != -1 || value.indexOf(",") != -1) {
            for (var i = value.length - 1; i > 0; i--) {
                var val = value.substring(i, i + 1);
                if (val == ".") {
                    break;
                }
                else if (val == ",") {
                    retval = false;
                    break;
                }
                else if (i > 5) {
                    break;
                }
            }
        }
    }
    return retval;
}

function getMonthFromString(month) {
    var d = Date.parse(month + "1, " + new Date().getFullYear());
    if (!isNaN(d)) {
        return new Date(d).getMonth() + 1;
    }
    return month;
}

function get_htmlcontent(content) {
    var value = content.replace(/<[^>]+>/g, '')
    return value;
}

/****************/
/*** DATA SEARCH ***/
/****************/

function compareValue(type, key, ignoredFields, val1, val2) {
    if (ignoredFields != "" && ignoredFields != null) {
        var ignoredArray = ignoredFields.split(",");
        for (var i = 0; i < ignoredArray.length; i++) {
            if (key == ignoredArray[i]) {
                return false;
            }
        }
    }

    if (type == "equals") {
        if (val1 == val2) {
            return true;
        }
        else {
            return false;
        }
    } else if (type == "contains") {
        if (val1.includes(val2)) {
            return true;
        }
        else {
            return false;
        }
    } else if (type == "startswith") {
        if (val1.startsWith(val2)) {
            return true;
        }
        else {
            return false;
        }
    }
    else if (type == "endswith") {
        return val1.endsWith(val2)
    }
}

var dataSearch = function (data, filterValue, orderFilter, ignoredFields) {
    var lowercasedFilter = filterValue.toLowerCase();
    var filterType = 1;

    if (lowercasedFilter.indexOf("_searchfiltertype_2") != -1) {
        filterType = 2;
        lowercasedFilter = lowercasedFilter.replace("_searchfiltertype_2", "");
    }
    else if (lowercasedFilter.indexOf("_searchfiltertype_3") != -1) {
        filterType = 3
        lowercasedFilter = lowercasedFilter.replace("_searchfiltertype_3", "");
    }
    else  {
        lowercasedFilter = lowercasedFilter.replace("_searchfiltertype_1", "");
    }

    var filterArray = lowercasedFilter.split("|");
    var filteredData = data;

    for (var i = 0; i < filterArray.length; i++) {
        if (filterArray[i].indexOf("_productcodefilter_") != -1) {
            var filter = filterArray[i].replace("_productcodefilter_", "").replace("_", "");
            if (filter != "") {
                filteredData = filteredData.filter(item => {
                    return Object.keys(item).some(key =>
                        compareValue("equals", key, ignoredFields, item["productcodeid"].toString().toLowerCase(), filter)
                    );
                });
            }
        } else if (filterArray[i].indexOf("_categoryfilter_") != -1) {
            var filter = filterArray[i].replace("_categoryfilter_", "").replace("_", "");
            if (filter != "") {
                filteredData = filteredData.filter(item => {
                    return Object.keys(item).some(key =>
                        compareValue("equals", key, ignoredFields, item["categoryid"].toString().toLowerCase(), filter)
                    );
                });
            }
        } else if (filterArray[i].indexOf("_typefilter_") != -1) {
            var filter = filterArray[i].replace("_typefilter_", "").replace("_", "");
            if (filter != "") {
                filteredData = filteredData.filter(item => {
                    return Object.keys(item).some(key =>
                        compareValue("contains", key, ignoredFields, item["type"].toString().toLowerCase(), filter)
                    );
                });
            }
        } else if (filterArray[i].indexOf("_workcenterfilter_") != -1) {
            var filter = filterArray[i].replace("_workcenterfilter_", "").replace("_", "");
            if (filter != "") {
                filteredData = filteredData.filter(item => {
                    return Object.keys(item).some(key =>
                        compareValue("contains", key, ignoredFields, item["workcenter"].toString().toLowerCase(), filter)
                    );
                });
            }
        } else if (filterArray[i].indexOf("_procurementfilter_") != -1) {
            var filter = filterArray[i].replace("_procurementfilter_", "").replace("_", "");
            if (filter != "") {
                filteredData = filteredData.filter(item => {
                    return Object.keys(item).some(key =>
                        compareValue("contains", key, ignoredFields, item["procurement"].toString().toLowerCase(), filter)
                    );
                });
            }
        } else if (filterArray[i].indexOf("mrpdate") != -1) {
            continue;
        } else {
            var filter = filterArray[i];
            if (filter != "") {
                if (filterType == 2) {
                    filteredData = filteredData.filter(item => {
                        return Object.keys(item).some(key =>
                            compareValue("startswith", key, ignoredFields, item[key].toString().toLowerCase(), filter)
                        );
                    });
                }
                else if (filterType ==3) {
                    filteredData = filteredData.filter(item => {
                        return Object.keys(item).some(key =>
                            compareValue("endswith", key, ignoredFields, item[key].toString().toLowerCase(), filter)
                        );
                    });
                }
                else {
                    filteredData = filteredData.filter(item => {
                        return Object.keys(item).some(key =>
                            compareValue("contains", key, ignoredFields, item[key].toString().toLowerCase(), filter)
                        );
                    });
                }
            }
        }
    }

    if (orderFilter != "" && orderFilter != null) {
        filteredData = filteredData.filter(item => {
            return Object.keys(item).some(key =>
                item["productionorder"].toString().toLowerCase().includes(orderFilter)
            );
        });
    }

    return filteredData;
}

/****************/
/*** HELPER FUNCTIONS ***/
/****************/

var getFilterValue = function (field, filterValue) {
    var retval = "";
    if (filterValue != "" && filterValue != null) {
        var filterArray = filterValue.split("|");
        for (var i = 0; i < filterArray.length; i++) {
            if (field == "") {
                if (filterArray[i].indexOf("_ProductCodeFilter_") == -1 && filterArray[i].indexOf("_CategoryFilter_") == -1 && filterArray[i].indexOf("_SearchFilterType_") == -1 && filterArray[i].indexOf("_StatusFilter_") == -1) {
                    retval = filterArray[i];
                }

            } else {
                if (filterArray[i].indexOf(field) != -1) {
                    retval = filterArray[i].replace(field, "");
                }
            }
        }
    }

    return retval;
}

var getDefaultDate = function (currentdate, addDay) {
    var d = new Date(currentdate);
    d.setDate(d.getDate() + addDay);
    var month = (d.getMonth() + 1 > 12) ? 1 : d.getMonth() + 1;
    month = (month.toString().length > 1) ? month : "0" + month;
    var day = d.getDate();
    day = (day.toString().length > 1) ? day : "0" + day;
    return d.getFullYear() + "-" + month + "-" + day;
}

var getPageName = function (pathname) {
    var pagename = pathname;
    if (pathname != undefined) {
        if (pathname != "") {
            pagename = pathname.substring(pathname.indexOf("/") + 1);
        }
    }
    return pagename;
}

var isNumeric = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

var getDayOfWeekHeader = function (dayofweek = 0, weekoffset = 0) {
    var d = new Date();
    d.setDate(d.getDate() + (((dayofweek + 7 - d.getDay()) % 7) || 7) + (7 * (weekoffset-1)));
    return d.getDate() + "-" + d.toLocaleString('default', { month: 'short' });
}

var getWeekNumberRangeHeader = function (startDate = new Date(), weekstoinclude = 0) {
    startDate = new Date(startDate);
    var startWeekNumber = getWeekNumber(new Date(startDate));
    var endDate = new Date(new Date(startDate).setDate(startDate.getDate() + (weekstoinclude * 7)));
    var endWeekNumber = getWeekNumber(endDate);
    return startWeekNumber + " to " + endWeekNumber;
}

var getWeekNumber = function (d) {
    d = new Date(d)
    var onejan = new Date(d.getFullYear(), 0, 1);
    var today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    var dayOfYear = ((today - onejan + 86400000) / 86400000);
    return Math.ceil(dayOfYear / 7)
}

/****************/
/*** STYLING ***/
/****************/

var linkColumnStyle = {
    color: "#054C8E",
    textDecorationLine: "underline",
    cursor: "pointer"
}

var errorColumnStyle = {
    color: "#FF0000"
}

var pageHeaderStyle = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif',
    color: "#0F76BD",
    fontSize: "x-large",
    fontWeight: "bold",
    fontStyle: "normal",
    display: 'inline-block'
}

var displayNameStyle = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif',
    color: "#414042",
    fontSize: "10",
    fontWeight: "600",
    fontStyle: "normal"
}

var displayTitleStyle = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif',
    color: "#6D6E71",
    fontSize: "10",
    fontWeight: "400"
}

var buttonBlue = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif',
    backgroundColor: "#3A4757",
    fontSize: "14",
    color: "white",
    fontWeight: "600",
    borderRadius: "4px",
    border: "2px solid gray",
    padding: "0px 5px 0px 5px !important"
}

var buttonWhite = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif',
    backgroundColor: "white",
    fontSize: "14",
    color: "#3A4757",
    fontWeight: "600",
    borderRadius: "4px",
    border: "2px solid gray",
    padding: "0px 5px 0px 5px !important"
}

var buttonBlueSmall = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif',
    backgroundColor: "#3A4757",
    fontSize: "14",
    color: "white",
    fontWeight: "600",
    borderRadius: "4px",
    border: "2px solid gray",
    padding: "0px 5px 0px 5px !important",
    height: "20px"
}

var buttonWhiteSmall = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif',
    backgroundColor: "white",
    fontSize: "14",
    color: "#3A4757",
    fontWeight: "600",
    borderRadius: "4px",
    border: "2px solid gray",
    padding: "0px 5px 0px 5px !important",
    height: "20px"
}

var Search = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.header.background};
  display: none;
  position: relative;
  width: 90%;

  &:hover {
    background-color: ${props => darken(0.05, props.theme.header.background)};
  }

  ${props => props.theme.breakpoints.up("sm")} {
    display: block;
  }
`;

var SearchIconWrapper = styled.div`
  width: 120px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    width: 20px;
    height: 20px;
  }

&& {
    color: ${props => props.theme.sidebar.header.background};
}
`;

var SearchInput = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${props => props.theme.header.search.color};
    padding-top: ${props => props.theme.spacing(2.5)}px;
    padding-right: ${props => props.theme.spacing(15)}px;
    padding-bottom: ${props => props.theme.spacing(2.5)}px;
    padding-left: ${props => props.theme.spacing(15)}px;
    width: 160px;
  }

  > div .MuiSelect-root {
    width: 70px;
  }
`;

var SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

var TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${props => props.theme.spacing(12)}px);
`;

/****************/
/*** SHARED FUNCTIONS ***/
/****************/

var renderHTML = function (value, key) {
    var retval = value;
    if (retval != null &&  retval != undefined && retval != "" && typeof (retval) == "string") {
        if (retval.indexOf("<") != -1 && retval.indexOf(">") != -1) {
            retval = <span dangerouslySetInnerHTML={{ __html: retval }} />;
        } else if (retval.indexOf("&#10004;") !== -1) {
            // Render the Check character
            retval = <span dangerouslySetInnerHTML={{ __html: retval }} />;
        }
    }

    return retval;
}

var handleClick = function (value, key) {
    return (event) => {
        var myURL = "";
        if (key == "SalesOrder") {
            myURL = '../frmSALES_DataEntry.aspx?OrderType=SALES%20ORDER&OrderID=' + value;
        }

        var w = 1100;
        var h = 775;

        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;

        return (
            windowopen(myURL, '_blank', 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
        );

    }
}

var redirectCompanyCode = function (companycodeid, plantid, pagename) {
    if (!(isNaN(companycodeid))) {
        if (pagename === undefined) {
            pagename = "";
        }

        var myURL = "../frmSYSTEM_ChangeCompanyCode.aspx?NewCompanyCodeID=" + companycodeid + "&NewPlantID=" + plantid + "&RedirectPageName=" + pagename;
        var target = '_self';

        var w = 1100;
        var h = 775;

        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;

        return (
            windowopen(myURL, target, 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
        );
    }
}

var redirectPlant = function (plantid, pagename) {
    if (!(isNaN(plantid))) {
        sessionStorage.setItem('PlantID', plantid);

        var myURL = '../frmSYSTEM_ChangeCompanyCode.aspx?NewPlantID=' + plantid + "&RedirectPageName=" + pagename;
        var target = '_self';

        var w = 1100;
        var h = 775;

        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;

        return (
            windowopen(myURL, target, 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
        );
    }
}

/****************/
/*** MISC ***/
/****************/

function doNothing() {
    //Do Nothing
}

/****************/
/*** COMPONENTS ***/
/****************/

export class ViewSelect extends React.Component {
    userInfo = this.props.userInfo || getUserInfo()
    state = {
        userInfo: this.userInfo,
        value: this.props.value,
        labelWidth: 0,
        companycodes: this.userInfo.companycodes,
        data: this.props.values,
        name: this.props.name,
        defaultLabel: this.props.defaultLabel
    };

    handleChange = event => {
        this.setState({ value: event.target.value });
        var filterValue = this.state.name + event.target.value;
        this.props.handleFilterChange(filterValue);
    };

    render = () => {
        const data = this.props.values;
        var value = this.state.value;

        if (data.length < 1) {
            value = this.props.defaultValue;
        }
        else {
            var found = false;
            for (var i = 0; i < data.length; i++) {
                if (data[i].value == value) {
                    found = true;
                    break;
                }
            }
            if (found === false) {
                value = "_";
            }
        }

        const { labelWidth, companycodes, name, defaultLabel } = this.state;
        let displayError = false;
        if (this.props.required) {
            if (value === "_") {
                displayError = true;
            }
        }

        return (
            <FormControl error={displayError}>
            <Select
                value={value}
                onChange={this.handleChange}
                disableUnderline
                variant={displayError ? "outlined":"standard"}
                style={this.state.name == "_CompanyCodeFilter_" || this.state.name == "_PlantFilter_" ? { color: '#0F76BD', fontWeight: '600' } : null}
            >
                {defaultLabel !== "" ? <MenuItem value="_" key="default">{defaultLabel}</MenuItem> : null}
                {data.map((item, index) => (
                    <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                ))}
                </Select>
                {displayError ? <FormHelperText>Value must be selected.</FormHelperText> : null}
            </FormControl>
        );
    }
}

export class DateSelect extends React.Component {
    state = {
        value: this.props.values,
        name: this.props.name,
        label: this.props.label
    };

    handleChange = event => {
        // Check if Date is Valid before doing onchange
        var tmpDate = new Date(event.target.value);
        if (tmpDate.getFullYear() > 1900) {
            this.setState({ value: event.target.value });
            var filterValue = this.state.name + event.target.value;
            this.props.handleFilterChange(filterValue);
        }
        else {
            var defaultDate = getDefaultDate("1985-01-01T00:00:00");
            this.setState({ value: defaultDate });
            var filterValue = this.state.name + defaultDate;
            this.props.handleFilterChange(filterValue);
        }
    };

    render() {
        const { value, name, label } = this.state;

        return (
            <form noValidate>
                <TextField
                    id={name}
                    label={label}
                    type="date"
                    defaultValue={value}
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={this.handleChange}
                />
            </form>
        );
    }
}

export class NewButton extends React.Component {
    newButtonClick = () => {
        var myURL = this.props.buttonUrl;
        var target = '_blank';

        if (this.props.currentWindow) {
            target = '_self';
        }

        var w = 1100;
        var h = 775;

        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;

        if (this.props.fullScreen) {
            w = window.screen.width;
            h = window.screen.height;

            left = 0;
            top = 0;
        }

        return (
            windowopen(myURL, target, 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=' + w + ',height=' + h + ',left=' + left + ',top=' + top + ',modal=yes,fullscreen=yes')
        );
    }

    render = () => {
        return (
            <Button variant="outlined" color="primary" mt={2} onClick={this.newButtonClick} style={(this.props.buttonStyle) ? this.props.buttonStyle : buttonWhite} size={(this.props.buttonSize) ? this.props.buttonSize : "medium"}>
                {this.props.buttonName}
            </Button>
        );
    }
}

export class PageHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            searchFilterTypes: [{ "name": "Contains", "value": "1" }, { "name": "Starts with", "value": "2" }, {"name": "Ends with", "value": "3"}],
            searchFilter: sessionStorage.getItem(this.props.pageName + '_searchFilter') || "_SearchFilterType_1",
            pageName: this.props.pageName || "",
            badge: this.props.badge,
            ispreview: this.props.isPreview,
            is24SO: this.props.is24SO,
            showSetup: this.props.showSetup || false,
            showDetails: this.props.showDetails || false,
            disableEnter: this.props.disableEnter || false
        };
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleSearchFilterTypeChange = this.handleSearchFilterTypeChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handlePreviewClick = this.handlePreviewClick.bind(this);
        this.handleSetupOpen = this.handleSetupOpen.bind(this);
        this.handleMenuChange = this.handleMenuChange.bind(this);
    }

    handleCompanyChange = (param) => {
        var companycodeid = Number(getSelectedCompanyCodeID());
        var plantid = Number(sessionStorage.getItem('default_plant')) || getDefaultPlantID(getSelectedCompanyCodeID());
        if (param.indexOf("_CompanyCodeFilter_") != -1) {
            companycodeid = param.replace("_CompanyCodeFilter_", "");
            sessionStorage.setItem('default_companycode', companycodeid);

            plantid = getDefaultPlantID(companycodeid);
            sessionStorage.setItem('default_plant', plantid);
            this.setState({
                isLoaded: false,
                companycodeID: companycodeid,
                plantID: plantid
            });
        }
        else if (param.indexOf("_PlantFilter_") != -1) {
            plantid = param.replace("_PlantFilter_", "");
            sessionStorage.setItem('default_plant', plantid);
            this.setState({
                isLoaded: false,
                plantID: plantid
            });
            
        }
        redirectCompanyCode(companycodeid, plantid, this.state.pageName);
    }

    handleSearchFilterTypeChange = (filter) => {
        var searchfiltername = this.state.pageName + '_searchFilter';
        var filterValue = "";
        var existingSearchFilter = sessionStorage.getItem(searchfiltername) || "";
        if (existingSearchFilter != "") {
            var filterArray = existingSearchFilter.split("|");
            for (var i = 0; i < filterArray.length; i++) {
                if (filterArray[i].indexOf("_SearchFilterType_") != -1 && filter.indexOf("_SearchFilterType_") != -1) {
                    continue;
                }

                if (filterValue != "") filterValue += "|";
                filterValue += filterArray[i];
            }

            if (filterValue != "") filterValue += "|";
            filterValue += filter;
        }
        else {
            filterValue = filter;
        }

        sessionStorage.setItem(searchfiltername, filterValue);
        this.setState({
            searchFilter: filterValue
        });
        this.props.handleSearch(filterValue);
    }

    handlePreviewClick = () => {
        var value = !this.state.ispreview;
        sessionStorage.setItem(this.state.pageName + '_ispreview', value);
        this.setState({ ispreview: value });
        this.props.handlePreviewClick(value);
    }

    handleSetupOpen = () => {
        var w = 1100;
        var h = 775;

        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;

        var target = '_blank';
        var myURL = '../frmSYSTEM_ConfigureScreen.aspx?ScreenName=React ' + this.state.pageName + '&TimeStamp=' + Date.now();
        if (this.props.showDetails != undefined) {
            if (this.state.showDetails === false) {
                myURL += "&CompactView=TRUE";
            } else {
                myURL += "&CompactView=FALSE";
            }
        } else {
            myURL += "&CompactView=ALL";
        }
        
        if (myURL != "") {
            return (
                windowopen(myURL, target, 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
            );
        }
    }

    handleSearch = (e) => {
        var searchfiltername = this.state.pageName + '_searchFilter';
        var filter = e.target.value;
        var filterValue = "";
        var existingSearchFilter = sessionStorage.getItem(searchfiltername) || "";
        if (existingSearchFilter != "") {
            var filterArray = existingSearchFilter.split("|");
            for (var i = 0; i < filterArray.length; i++) {
                if (filterArray[i].indexOf("_SearchFilterType_") != -1) {
                    if (filterValue != "") filterValue += "|";
                    filterValue += filterArray[i];
                }
            }

            if (filterValue != "") filterValue += "|";
            filterValue += filter;
        }
        else {
            filterValue = filter;
        }

        sessionStorage.setItem(searchfiltername, filterValue);
        this.setState({
            searchFilter: filterValue
        });
        this.props.handleSearch(filterValue);
    }

    handleMenuChange = (e) => {
        let name = e.target.name;
        let value;
        if (e.target.name == "showDetails") {
            value = e.target.checked;
            sessionStorage.setItem(this.state.pageName + '_showDetails', value);
            this.setState({
                showDetails: value
            });
        }
        this.props.handleMenuChange(name, value);
    }

    handleSearchButton = () => {
        if (this.state.searchFilter != "") {
            var filterArray = this.state.searchFilter.split("|");
            var id = -1;
            for (var i = 0; i < filterArray.length; i++) {
                if (filterArray[i].indexOf("_SearchFilterType_") != -1) {
                    continue;
                } else {
                    id = filterArray[i].toString().split(" - ")[0]
                    break;
                }
            }

            let companycodeid = this.props.companycodeID;
            if (this.props.companycodeID == undefined) {
                companycodeid = getSelectedCompanyCodeID()
            }

            var w = 1100;
            var h = 775;

            var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
            var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

            var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
            var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

            var left = ((width / 2) - (w / 2)) + dualScreenLeft;
            var top = ((height / 2) - (h / 2)) + dualScreenTop;

            var myURL = "";
            var target = '_blank';

            switch (this.state.pageName) {
                case 'sales_salesorders':
                case 'sales_deliveryblocks':
                    if (!(isNaN(id)) && id.length > 5) {
                        myURL = '../frmSALES_DataEntry.aspx?OrderType=SALES%20ORDER&OrderID=' + id.trim() + "&CompanyCodeID=" + this.props.companycodeID;

                        //if (this.state.is24SO == "TRUE" || this.state.isPreview == true) {
                        //    myURL = '../frmZSalesOrder.aspx?FromUI=TRUE&OrderType=SALES%20ORDER&OrderID=' + id.trim() + "&CompanyCodeID=" + this.props.companycodeID;
                        //    target = '_self';
                        //}
                    }
                    break;
                case 'sales_quotes':
                    if (!(isNaN(id)) && id.length > 5) {
                        myURL = '../frmSALES_DataEntry.aspx?CompanyCodeID=' + this.props.companycodeID + '&OrderType=QUOTE&OrderID=' + id.trim() + '&TimeStamp=' + Date.now();
                    }
                    break;
                case 'sales_customerreturns':
                    if (!(isNaN(id)) && id.length >= 5) {
                        myURL = '../frmSALES_CustomerReturns.aspx?CustomerReturnID=' + id.trim() + "&TimeStamp=" + Date.now();
                    }
                    break;
                case 'crm_opportunities':
                    if (!(isNaN(id)) && id.length >= 1 && id >= 1) {
                        myURL = '../frmCRM_AddOpportunity.aspx?CompanyCodeID=' + companycodeid + "&OpportunityID=" + id + "&TimeStamp=" + Date.now();
                    }
                    break;
                case 'crm_leads':
                    if (!(isNaN(id)) && id.length >= 1 && id >= 1) {
                        myURL = '../frmCRM_AddLead.aspx?CompanyCodeID=' + companycodeid + "&LeadID=" + id.trim() + "&TimeStamp=" + new Date().getTime();
                    }
                    break;
                case 'manufacturing_productionorders':
                    if (!(isNaN(id)) && id.length > 5) {
                        myURL = '../frmMFG_ProductionOrders.aspx?OrderType=PRODUCTION%20ORDER&ProductionOrderID=' + id.trim() + "&CompanyCodeID=" + companycodeid + "&TimeStamp=" + new Date().getTime();
                    }
                    break;
                case 'purchasing_requestforquotes':
                    if (!(isNaN(id)) && id.length > 5) {
                        myURL = '../frmPURCHASING_DataEntry.aspx?CompanyCodeID=' + companycodeid + '&OrderType=RFQ&OrderID=' + id.trim();
                    }
                    break;
                case 'purchasing_purchaseorders':
                    if (!(isNaN(id)) && id.length > 5) {
                        myURL = '../frmPURCHASING_DataEntry.aspx?CompanyCodeID=' + companycodeid + '&OrderType=PURCHASE%20ORDER&OrderID=' + id.trim();

                        //if (this.state.is24SO == "TRUE" || this.state.isPreview == true) {
                        //    myURL = '../frmZPurchaseOrder.aspx?FromUI=TRUE&CompanyCodeID=' + companycodeid + '&OrderType=PURCHASE%20ORDER&OrderID=' + id.trim();
                        //    target = '_self';
                        //}
                    }
                    break;
                case 'purchasing_supplierreturns':
                    if (!(isNaN(id)) && id.length >= 5) {
                        myURL = '../frmPURCHASING_SupplierReturns.aspx?SupplierReturnID=' + id.trim() + "&TimeStamp=" + Date.now();
                    }
                    break;
                default:
                    break;
            }

            if (myURL != "") {
                return (
                    windowopen(myURL, target, 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
                );
            }
        }
    }

    render = () => {

        let defaultSearchFilterType = getFilterValue("_SearchFilterType_", this.state.searchFilter) || "1";
        let defaultSearchFilter = getFilterValue("", this.state.searchFilter) || "";

        let userinfo = this.props.userInfo || getUserInfo();
        let companycodes = getCompanyCodes(userinfo);
        let plants = getPlants(this.props.companycodeID, userinfo);

        let { badge, ispreview, is24SO, showSetup, showDetails } = this.state;

        return (
            <Grid justifyContent="space-between" container className="print-hidden">
                <PageHeaderTitleComponent 
                    title={this.props.title}
                    badge={badge}
                    isPreview={ispreview}
                    is24SO={is24SO}
                    handlePreviewClick={this.handlePreviewClick}
                    showSetup={showSetup}
                    handleSetupOpen={this.handleSetupOpen}
                    showDetails={this.props.showDetails}
                    handleMenuChange={this.handleMenuChange}
                />
                {this.props.hideSearch? 
                    <></>
                :
                    <Grid item xs={4}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <SearchInput
                                placeholder="Filter"
                                onChange={this.handleSearch}
                                value={defaultSearchFilter}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter' && this.state.disableEnter === false) {
                                        this.handleSearchButton();
                                    }
                                }}
                                startAdornment={<ViewSelect
                                    handleFilterChange={this.handleSearchFilterTypeChange}
                                    values={this.state.searchFilterTypes}
                                    value={defaultSearchFilterType}
                                    name="_SearchFilterType_"
                                    userInfo={userinfo}
                                />}
                            />
                        </Search>
                    </Grid>
                }
                <Grid item >
                    <SmallButton size="small" mr={2} onClick={this.props.refreshClick}>
                        <LoopIcon />
                    </SmallButton>
                    {this.props.companycodeID ? (
                        <React.Fragment>
                            <Typography gutterBottom display="inline" style={displayNameStyle}>
                                Company Code: &nbsp;
                            </Typography>
                            <ViewSelect
                                handleFilterChange={this.handleCompanyChange}
                                values={companycodes}
                                value={this.props.companycodeID}
                                name="_CompanyCodeFilter_"
                                userInfo={userinfo}
                                />
                       </React.Fragment>
                        ) : null
                    }
                    {this.props.plantID ? (
                        <React.Fragment>
                            <Typography gutterBottom display="inline" style={displayNameStyle}>
                               &nbsp;&nbsp;&nbsp; Plant: &nbsp;
                            </Typography>
                            <ViewSelect
                                handleFilterChange={this.handleCompanyChange}
                                values={plants}
                                value={this.props.plantID}
                                name="_PlantFilter_"
                                userInfo={userinfo}
                                />
                        </React.Fragment>
                        ) : null
                    }
                </Grid>
            </Grid>
        );
    }
}

export class StatusPanel extends React.Component {
    isLoaded = this.props.isLoaded;
    userInfo = this.props.userInfo || getUserInfo()
    state = {
        userInfo: this.userInfo,
        value: this.props.defaultValue,
        labelWidth: 0,
        companycodes: this.userInfo.companycodes,
        data: this.props.data,
        completed: 0,
        cycle: 0,
        display: 'none'
    };

    handleChange = (value) => {
        this.setState({ value: value });
        this.isLoaded = false;
        this.props.handleFilterChange(value);
        this.timer = setInterval(this.progress, 20);
    };

    isActive = ( value) => {
        if (value == this.state.value) //|| this.state.value.indexOf(value) !== -1)
            return true;
        else 
        {
            const splitValues = this.state.value.split("|");
            for (var i = 0; i < splitValues.length; i++){
                if (splitValues[i] === value){
                    return true;
                }
            }
            return false;
        }
    };

    progress = () => {
        const { completed, cycle } = this.state;

        if (cycle == 3 || this.isLoaded == true) {
            clearInterval(this.timer);
            this.setState({
                completed: 0,
                cycle: 0,
                display: 'none'
            });
        } else {
            this.setState({
                completed: completed >= 100 ? 0 : completed + 1,
                cycle: completed == 100 ? cycle + 1 : cycle,
                display: 'inline',
                isLoaded: false
            });
        }
    };

    render = () => {
        this.isLoaded = this.props.isLoaded;
        const data = this.props.data;
        const { value, labelWidth, companycodes, completed } = this.state;
        let display = this.state.display;
        const activeStyle = {backgroundColor: "#0F76BD", boxShadow: "inset 0px 0px 3px 1px #2196f3, 0 0px 0px 2px #2196f3"};
        const activeTextStyle = {fontWeight: "bold"}
        return (
            <Grid container spacing={1}>
                {data.map((item, index) => (
                    <Grid item key={index}>
                        < Button style={this.isActive(item.filter) ? {color: item.color, ...activeStyle} :{ color: item.color, backgroundColor: item.bgcolor} } variant="contained" mt={2} onClick={() => this.handleChange(item.filter)} >
                            {value === item.filter ?
                                (<CircularProgress
                                    mx={1}
                                    variant="determinate"
                                    value={completed}
                                    style={{ color: item.color, width: '15px', height: '15px', display: display, justify: 'flex-start' }}
                                />) : null
                            }
                            <div>
                                <Typography style={this.isActive(item.filter) ? {...activeTextStyle} : {} }>
                                    {item.label}
                                </Typography>
                                <Typography style={this.isActive(item.filter) ? { fontSize: '18px', ...activeTextStyle } : {fontSize: '18px'}} align='center'>
                                    {item.value}
                                </Typography>
                            </div>
                        </Button>
                    </Grid>
                ))}
            </Grid>
        );
    }
}

export class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        const orderBy = property;
        let order = "desc";

        if (this.props.orderBy === property && this.props.order === "desc") {
            order = "asc";
        }

        sessionStorage.setItem(this.props.pageName + '_order', order);
        sessionStorage.setItem(this.props.pageName + '_orderBy', orderBy);
        this.props.onRequestSort(order, orderBy);
    };

    render() {
        const {
            onSelectAllClick,
            order,
            orderBy,
            numSelected,
            rowCount,
            rows,
            pageName
        } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {rows.map(
                        (row, index) => (
                            <React.Fragment key={index}>
                                {
                                    (row.visible == "True" || row.visible == null || row.visible === true) ?
                                        (<TableCell
                                            key={row.id}
                                            align={(row.colSpan != null) ? "center" : (row.numeric == "True" || row.numeric == true) ? "right" : "left"}
                                            padding={(row.disablePadding == "True" || row.disablePadding == true) ? "none" : "normal"}
                                            sortDirection={orderBy === row.id ? order : false}
                                            colSpan={row.colSpan}
                                            style={row.bgColor ? { backgroundColor: row.bgColor }: {}}
                                        >
                                            <Tooltip
                                                title="Sort"
                                                placement={(row.numeric == "True" || row.numeric == true) ? "bottom-end" : "bottom-start"}
                                                enterDelay={300}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === row.id}
                                                    direction={order}
                                                    onClick={this.createSortHandler(row.id)}
                                                >
                                                    {row.label}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>) : null
                                }
                            </React.Fragment>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

export class ItemQuickAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            itemNumberError: false,
            unitPriceError: false,
            procurementError: false,
            productCodeError: false,
            itemTypeError: false,
            categoryError: false,
            procurementValue: "_",
            productCodeValue: "_",
            categoryValue: "_",
            itemTypeValue: "_",
            itemNumber: "",
            unitPrice: "",
            error: ""
        };
        this.handleProcurementChange = this.handleProcurementChange.bind(this);
        this.handleProductCodeChange = this.handleProductCodeChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleItemTypeChange = this.handleItemTypeChange.bind(this);
        this.handleItemNumberChange = this.handleItemNumberChange.bind(this);
        this.handleUnitPriceChange = this.handleUnitPriceChange.bind(this);
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = (e) => {
        this.setState({
            open: false,
            itemNumberError: false,
            unitPriceError: false,
            procurementError: false,
            productCodeError: false,
            itemTypeError: false,
            categoryError: false,
            procurementValue: "_",
            productCodeValue: "_",
            categoryValue: "_",
            itemTypeValue: "_",
            itemNumber: "",
            unitPrice: "",
            error: ""
        });
    };

    handleProcurementChange = (e) => {
        this.setState({ procurementValue: e.target.value, procurementError: false });
    };

    handleProductCodeChange = (e) => {
        this.setState({ productCodeValue: e.target.value, productCodeError: false });
    };

    handleCategoryChange = (e) => {
        this.setState({ categoryValue: e.target.value, categoryError: false });
    };

    handleItemTypeChange = (e) => {
        this.setState({ itemTypeValue: e.target.value, itemTypeError: false });
    };

    handleItemNumberChange = (e) => {
        this.setState({ itemNumber: e.target.value, itemNumberError: false });
    };

    handleUnitPriceChange = (e) => {
        this.setState({ unitPrice: e.target.value, unitPriceError: false });
    };

    CreateOrder = (e) => {
        const { procurementValue, productCodeValue, categoryValue, itemTypeValue, itemNumber, unitPrice } = this.state;

        if (itemNumber == "") {
            this.setState({ itemNumberError: true });
        }
        else if (unitPrice == "") {
            this.setState({ unitPriceError: true });
        }
        else if (procurementValue == "_") {
            this.setState({ procurementError: true });
        }
        else if (productCodeValue == "_") {
            this.setState({ productCodeError: true });
        }
        else if (categoryValue == "_") {
            this.setState({ categoryError: true });
        }
        else if (itemTypeValue == "_") {
            this.setState({ itemTypeError: true });
        }
        else {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                    //'Authorization': 'Bearer my-token'
                },
                body: JSON.stringify({
                    "ApiKey": this.props.userinfo.apikey,
                    "ItemNumber": itemNumber,
                    "UnitPrice": unitPrice,
                    "Procurement": procurementValue,
                    "ProductCode": productCodeValue,
                    "ItemType": itemTypeValue,
                    "Category": categoryValue
                })
            };

            fetch(this.props.userinfo.apiurl + "CreateItemUI", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        var resultString = JSON.stringify(result);
                        if (resultString.indexOf("Error Creating Item") != -1) {
                            this.setState({
                                error: resultString.replace(/[{}\"]/g, "")
                            });
                        }
                        else {
                            this.props.refreshClick();
                            this.handleClose();
                        }
                    },
                    (error) => {
                        this.setState({
                            error: "Error creating item."
                        });
                    }
                )
        }
    };

    render = () => {
        const { open, procurementValue, productCodeValue, categoryValue, itemTypeValue, procurementError, productCodeError, categoryError, itemTypeError, itemNumberError, unitPriceError, error } = this.state;
        const apikey = this.props.apikey;
        const procurement = this.props.procurement;
        const categories = this.props.categories;
        const productcodes = this.props.productcodes;
        const itemtypes = this.props.itemtypes;

        return (
            <React.Fragment>
                <Button variant="outlined" color="primary" onClick={this.handleClickOpen} style={buttonBlue}>
                    Quick Add
                </Button>
                <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth={true} >
                    <DialogTitle id="form-dialog-title" style={{ background: '#4f7091', color: 'white' }} >Add Item</DialogTitle>
                    <form>
                        <DialogContent style={{ marginTop: '15px', height: '250px' }}>
                            <Grid container spacing={6}>
                                <Grid item xs={6}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="ItemNumber"
                                        label="Item Number"
                                        fullWidth
                                        error={itemNumberError}
                                        onChange={this.handleItemNumberChange}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        margin="dense"
                                        id="UnitPrice"
                                        label="Unit Price"
                                        fullWidth
                                        type='number'
                                        onChange={this.handleUnitPriceChange}
                                        error={unitPriceError}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={6}>
                                <Grid item xs={6}>
                                    <Select fullWidth
                                        value={procurementValue}
                                        onChange={this.handleProcurementChange}
                                        error={procurementError}
                                        variant="outlined"
                                    >
                                        <MenuItem value="_" key="default">Select Procurement</MenuItem>
                                        {procurement.map((item, index) => (
                                            <MenuItem value={item.name} key={index}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={6}>
                                    <Select fullWidth
                                        value={itemTypeValue}
                                        onChange={this.handleItemTypeChange}
                                        error={itemTypeError}
                                        variant="outlined"
                                    >
                                        <MenuItem value="_" key="default">Select Item Type</MenuItem>
                                        {itemtypes.map((item, index) => (
                                            <MenuItem value={item.name} key={index}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={6}>
                                <Grid item xs={6}>
                                    <Select fullWidth
                                        value={productCodeValue}
                                        onChange={this.handleProductCodeChange}
                                        error={productCodeError}
                                        variant="outlined"
                                    >
                                        <MenuItem value="_" key="default">Select Product Code</MenuItem>
                                        {productcodes.map((item, index) => (
                                            <MenuItem value={item.name} key={index}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={6}>
                                    <Select fullWidth
                                        value={categoryValue}
                                        onChange={this.handleCategoryChange}
                                        error={categoryError}
                                        variant="outlined"
                                    >
                                        <MenuItem value="_" key="default">Select Category</MenuItem>
                                        {categories.map((item, index) => (
                                            <MenuItem value={item.name} key={index}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogContentText style={{ color: 'red', marginTop: '10px' }}>
                            {error}
                        </DialogContentText>
                        <DialogActions>
                            <Button color="primary" variant="outlined" onClick={this.CreateOrder} style={buttonBlue}>
                                Create
                        </Button>
                            <Button onClick={this.handleClose} variant="outlined" color="primary" style={buttonWhite}>
                                Cancel
                        </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}

export class ScreenSettings extends React.Component {
    state = {
        open: false,
        error: "",
        rows: [],
        buttons: [],
        updatedRows: [],
        updatedButtons: []
    };

    componentDidMount() {
        this.setState({
            open: false,
            error: "",
            rows: this.props.rows,
            buttons: this.props.buttons,
            updatedRows: this.props.rows,
            updatedButtons: this.props.buttons
        });
    }

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleCancel = (e) => {
        const oldButtons = this.state.buttons;
        const oldRows = this.state.rows;

        this.setState({
            open: false,
            error: "",
            updatedButtons: oldButtons,
            updatedRows: oldRows
        });
    };

    handleClose = (e) => {
        this.setState({
            open: false,
            error: ""
        });
    };

    handleChange = (e) => {
        var jsonObj = this.state.updatedRows;
        if (e.target.value === "button") {
            jsonObj = this.state.updatedButtons;
        }

        for (var i = 0; i < jsonObj.length; i++) {
            if (jsonObj[i].id === e.target.name) {
                jsonObj[i].visible = e.target.checked;
                break;
            }
        }

        if (e.target.value === "button") {
            this.setState({
                updatedButtons: jsonObj
            });
        }
        else {
            this.setState({
                updatedRows: jsonObj
            });
        }
    };

    SaveSettings = (e) => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                    //'Authorization': 'Bearer my-token'
                },
                body: JSON.stringify({
                    "ApiKey": this.props.userinfo.apikey,
                    "PageName": this.props.pageName,
                    "Screen": this.props.screen,
                    "Rows": this.state.updatedRows,
                    "Buttons": this.state.updatedButtons
                })
            };

            fetch(this.props.userinfo.apiurl + "UpdateScreenSettings", requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        var resultString = JSON.stringify(result);
                        if (resultString.indexOf("Error updating settings") != -1) {
                            this.setState({
                                error: resultString.replace(/[{}\"]/g, "")
                            });
                        }
                        else {
                            this.props.refreshClick();
                            this.handleClose();
                        }
                    },
                    (error) => {
                        this.setState({
                            error: "Error updating settings."
                        });
                    }
                )
    };

    render = () => {
        let { open, error, updatedRows, updatedButtons} = this.state;
        const { title, blankLabel } = this.props;

        return (
            <React.Fragment>
                <SmallButton size="small" onClick={this.handleClickOpen} >
                    <SettingsIcon />
                </SmallButton>
                <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth={true} >
                    <DialogTitle id="form-dialog-title" style={{ background: '#4f7091', color: 'white' }} >{title}</DialogTitle>
                    <form>
                        <DialogContent style={{ marginTop: '10px', height: '350px' }}>
                            <Grid container spacing={6}>
                                <Grid item xs={6}>
                            <FormLabel component="legend">Columns:</FormLabel>
                            <FormGroup>
                             {updatedRows.map(
                                    row => (
                                        <FormControlLabel
                                            control={<Switch checked={row.visible} onChange={this.handleChange} name={row.id} value="column" />}
                                            label={row.label || blankLabel} key={row.id}
                                        />
                                    ),
                                    this
                                )}
                            </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                            <FormLabel component="legend">Buttons:</FormLabel>
                            <FormGroup>
                                {updatedButtons.map(
                                    button => (
                                        <FormControlLabel
                                            control={<Switch checked={button.visible} onChange={this.handleChange} name={button.id} value="button" />}
                                            label={button.label || blankLabel} key={button.id}
                                        />
                                    ),
                                    this
                                )}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <br />
                        <DialogContentText style={{ color: 'red', marginTop: '10px', marginLeft: '10px' }}>
                            {error}
                        </DialogContentText>
                        <DialogActions>
                            <Button color="primary" variant="contained" onClick={this.SaveSettings}>
                                Save
                        </Button>
                            <Button color="primary" variant="contained" onClick={this.handleCancel}>
                                Cancel
                        </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}

var CircularProgressIndicator = function (obj) {
    const [progress, setProgress] = React.useState(0);
    const [cycle, setCycle] = React.useState(0);

    var prevCycle = 0;
    var prevProgress = 0;

    var maxCycle = 0; // 10 = 1 cycle
    if (obj.value > 1000) {
        maxCycle = Math.ceil(obj.value * (10/1000));
    }
    else if (obj.value > 10000) {
        maxCycle = 30 + Math.ceil(obj.value * (10 / 10000));
    }
    else if (obj.value > 50000) {
        maxCycle = 50 + Math.ceil(obj.value * (10 / 50000));
    }
    else if (obj.value > 100000) {
        maxCycle = 100 + Math.ceil(obj.value * (10 / 100000));
    }
    else if (obj.value > 500000) {
        maxCycle = 150 + Math.ceil(obj.value * (10 / 500000));
    }

    React.useEffect(() => {
        const timer = setInterval(() => {
            if (obj.value > 1000) {
                prevCycle = (prevCycle >= maxCycle ? prevCycle : prevCycle + 1);
                prevProgress = (prevProgress >= 100 ? 0 : prevProgress + 10);

                setProgress(prevProgress);
                if (prevCycle >= maxCycle) {
                    setProgress(0);
                    clearInterval(timer);
                }
                else {
                    setCycle(prevCycle);
                }
            }
            else {
                clearInterval(timer);
            }

        }, 200);

        return () => {
            clearInterval(timer);
        };
    }, [obj.value]);

    return (
        <CircularProgress
            variant="determinate"
            value={progress}
            mx={1}
            style={{ width: '15px', height: '15px' }} />
    );
}

export class ConfirmDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            error: ""
        };
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = (e) => {
        this.setState({
            open: false,
            error: ""
        });
    };

    handleConfirm = (e) => {
        this.setState({
            open: false,
            error: ""
        });
        this.props.handleConfirm();
    };

    render = () => {
        const { error } = this.state;

        return (
            <React.Fragment>
                <Typography>
                    {this.props.title}
                </Typography>
                <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" maxWidth='sm' fullWidth={true} >
                    <DialogTitle id="form-dialog-title" style={{ background: '#4f7091', color: 'white' }} >{this.props.title}</DialogTitle>
                    <form>
                        <DialogContentText style={{ color: 'red', marginTop: '20px', marginLeft: '20px', marginRight: '20px' }}>
                            {this.props.description}
                        </DialogContentText>
                        <DialogContentText style={{ color: 'red', marginTop: '10px', marginLeft: '10px' }}>
                            {error}
                        </DialogContentText>
                        <DialogActions>
                            <Button color="primary" variant="outlined" onClick={this.handleConfirm} style={buttonBlue}>
                                Ok
                            </Button>
                            <Button onClick={this.handleClose} variant="outlined" color="primary" style={buttonWhite}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    }
}

/****************/
/*** DEBUG FUNCTIONS ***/
/****************/
var getDebugFlag = function () {
    var myValue = "";

    var cookieArray = document.cookie.split(";");
    cookieArray.map(function (originalValue) {
        originalValue = originalValue.trim();
        if (originalValue.indexOf("MPDebugMode") == 0) {

            myValue = originalValue;
            myValue = myValue.replace("MPDebugMode=", "")
        }
    });

    if (myValue != "") {
        if (myValue.toLowerCase() === 'true') {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
};

var writeDebugLine = function (value) {
    var myValue = "";

    if (value === null) {
        return false;
    }

    var cookieArray = document.cookie.split(";");
    cookieArray.map(function (originalValue) {
        originalValue = originalValue.trim();
        if (originalValue.indexOf("MPDebugMode") == 0) {

            myValue = originalValue;
            myValue = myValue.replace("MPDebugMode=", "")
        }
    });

    if (myValue != "") {
        if (myValue.toLowerCase() === 'true') {
            console.log(value.toString());
        }
    }
};

/****************/
/*** EXPORTS ***/
/****************/

export var getUserInfo;
export var getApiURL;
export var getApiKey;
export var getCompanyCodes;
export var getCompanyCodeName;
export var getDefaultCompanyCodeID;
export var getSelectedCompanyCodeID;
export var getPlants;
export var getPlantName;
export var getDefaultPlantID;
export var checkLoginStatus;
export var encodeComponent;

export var stableSort;
export var getSorting;

export var dataSearch;
export var getFilterValue;
export var getDefaultDate;
export var getPageName;
export var getDayOfWeekHeader;
export var getWeekNumberRangeHeader;

export var linkColumnStyle;
export var errorColumnStyle;
export var pageHeaderStyle;
export var displayNameStyle;
export var displayTitleStyle;
export var buttonBlue;
export var buttonWhite;
export var buttonWhiteSmall;
export var buttonBlueSmall;

export var Search;
export var SearchIconWrapper;
export var SearchInput;
export var SmallButton;
export var TableWrapper;

export var renderHTML;
export var handleClick;
export var redirectCompanyCode;
export var redirectPlant;

export var CircularProgressIndicator;

export var getDebugFlag;
export var writeDebugLine;

export default function windowopen(url, target, features) {
    var userinfo = getUserInfo();
    if (userinfo.apiurl.indexOf("localhost") != -1) {
        url = url.replace("..", "https://localhost:44306");
    }

    
    if (url.indexOf("&") > -1 || url.indexOf("?") > -1) {
        url += "&NewUI=TRUE";
    //} else if (url.substring(url.length - 1) == "/") {
    //    url = url.substring(0, url.length - 1)
    //    url += "?NewUI=TRUE";
    } else {
        url += "?NewUI=TRUE";
    }
    window.open(url, target, features);
}