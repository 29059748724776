import React from "react";
import styled from "styled-components";
import Helmet from 'react-helmet';
import '../MasterplanERP.css';

import {
    CardContent,
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    Paper as MuiPaper,
    LinearProgress,
    Button,
    Modal,
    Box
} from "@material-ui/core";
import modalContainerStyle from "../../theme/modalcontainer";
import ComingSoonComponent from "../pages/ComingSoon";
import { spacing } from "@material-ui/system";

import windowopen, {
    getSelectedCompanyCodeID,
    getUserInfo,
    stableSort,
    getSorting,
    dataSearch,
    linkColumnStyle,
    errorColumnStyle,
    ViewSelect,
    NewButton,
    StatusPanel,
    EnhancedTableHead,
    renderHTML,
    TableWrapper,
    buttonBlue,
    PageHeader,
    getDefaultPlantID,
    getPageName
} from "../../components/Helper";

import Blank from "../pages/Blank";
import FeedbackModalComponent from "../customerPortal/modals/FeedbackModal";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const PageName = "sales_salesorders"; //getPageName(window.location.pathname);

const rows = [
    { id: "account", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Account", button: "false"},
    { id: "customer", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Customer", button: "false"},
    { id: "salesorder", numeric: "False", disablePadding: "False", link: "True", visible: "True", label: "Sales Order", button: "false"},
    { id: "ponumber", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "PO Number", button: "false"},
    { id: "quantity", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Quantity", button: "false"},
    { id: "shipped", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Shipped", button: "false"},
    { id: "itemnumber", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Item Number", button: "false"},
    { id: "description", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Description", button: "false"},
    { id: "requesteddate", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Requested Date", button: "false"},
    { id: "promisedate", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Promise Date", button: "false"},
    { id: "status", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Status", button: "false"},
    { id: "enteredby", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Entered By", button: "false"},
    { id: "projectnumber", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Project Number", button: "false"},
    { id: "projectmanager", numeric: "False", disablePadding: "False", link: "False", visible: "True", label: "Project Manager", button: "false"}
];

class MyOpenOrders extends React.Component {
    constructor(props) {
        super(props);
        this.userInfo = getUserInfo()
        this.state = {
            order: sessionStorage.getItem(PageName + '_order') || "asc",
            orderBy: sessionStorage.getItem(PageName + '_orderBy') || "salesorder",
            error: null,
            isLoaded: false,
            data: [],
            page: 0,
            rowsPerPage: Number(sessionStorage.getItem(PageName + '_rowsPerPage')) || 10,
            userinfo: this.userInfo,
            companycodeID: Number(getSelectedCompanyCodeID(this.userInfo)),
            filter: sessionStorage.getItem(PageName + '_filter') || "Open",
            allowed: "",
            filterData: [],
            displaycolumns: rows,
            showSetup: false,
            is24SO: "FALSE",
            isPreview: false, //sessionStorage.getItem('salesorders_isPreview') === 'true' || false,
            enablesearch: true,
            searchFilter: sessionStorage.getItem(PageName + '_searchFilter') || "_SearchFilterType_1",
            plantID: Number(sessionStorage.getItem('default_plant')) || getDefaultPlantID(getSelectedCompanyCodeID(this.userInfo), this.userInfo),
            showDetails: sessionStorage.getItem(PageName + '_showDetails') || "",
            descriptionLines: Number(sessionStorage.getItem(PageName +'_descriptionLines')) || 0,
            isCustomerPortal: props.isCustomerPortal ? props.isCustomerPortal : false,
            feedbackModalOpen: false,
            selectedItem: undefined,
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.refreshClick = this.refreshClick.bind(this);
        this.handlePreviewClick = this.handlePreviewClick.bind(this);
        this.handleMenuChange = this.handleMenuChange.bind(this);
    }

    fetchData(param, filter, showDetails) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                //'Authorization': 'Bearer my-token'
            },
            body: JSON.stringify({
                "CompanyCodeID": param,
                "PlantID": this.state.plantID,
                "ApiKey": this.state.userinfo.apikey,
                "Filter": filter,
                "PageName": this.state.isCustomerPortal ? `customer ${PageName}` : PageName,
                "Screen": this.state.isCustomerPortal ? "customersalesorders":"salesorders",
                "ShowDetails": showDetails == undefined ? this.state.showDetails : showDetails,
                "IsCustomer": this.state.isCustomerPortal,
                "CustomerCompanyID" : this.state.userinfo.customercompanyid,
                "CustomerContactID" : this.state.userinfo.customercontactid
            })
        };

        fetch(this.state.userinfo.apiurl + "ScreenKPI", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        companycodeID: param,
                        filter: filter,
                        isLoaded: true,
                        data: result.orders,
                        filterData: result.filters,
                        page: 0,
                        allowed: result.data ? (result.data[0].status ? 'false' : '') : (''),
                        showSetup: result.showSetup ? result.showSetup : false,
                        displaycolumns: result.columns ? result.columns : rows,
                        is24SO: "FALSE",
                        showDetails: result.showDetails ? result.showDetails : this.state.showDetails,
                        descriptionLines: result.descriptionLines
                    });
                },
                (error) => {
                    this.setState({
                        companycodeID: param,
                        filter: filter,
                        isLoaded: true,
                        page: 0,
                        error
                    });
                }
            )
    }

    componentDidMount() {
        this.fetchData(this.state.companycodeID, this.state.filter);
    }

    handleRequestSort = (order, orderBy) => {
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        sessionStorage.setItem(PageName + '_rowsPerPage', event.target.value);
        this.setState({rowsPerPage: event.target.value, page: 0});
    };

    handleFilterChange(filter) {
        sessionStorage.setItem(PageName + '_filter', filter);
        this.setState({ isLoaded: false });
        this.fetchData(this.state.companycodeID, filter);
    }

    handleSearch(filter) {
        this.setState({ searchFilter: filter });
    }

    refreshClick() {
        this.setState({ isLoaded: false });
        this.fetchData(this.state.companycodeID, this.state.filter);
    }

    handlePreviewClick = () => {
        var value = !this.state.isPreview;
        sessionStorage.setItem(PageName + '_isPreview', value);
        this.setState({ isPreview: value });
    }

    handleMenuChange(menu, value) {
        if (menu == "showDetails") {
            sessionStorage.setItem(this.state.pageName + '_showDetails', value);
            this.setState({ showDetails: value, isLoaded: false });
        }
        this.fetchData(this.state.companycodeID, this.state.filter, value);
    }
    handleFeedbackClick = (item) => {
        this.setState({
            selectedItem: item,
            feedbackModalOpen: true
        })
    }
    handleCloseModal = () => {
        this.setState({
            selectedItem: undefined,
            feedbackModalOpen: false
        })
    }
    handleSOClick = (id, column) => {
        return (event) => {
            var myURL = '../frmSALES_DataEntry.aspx?OrderType=SALES%20ORDER&OrderID=' + id.toString().split(" - ")[0] + "&CompanyCodeID=" + this.state.companycodeID;
            var target = '_blank';

            if (this.state.is24SO == "TRUE" || this.state.isPreview == true) {
                myURL = '../frmZSalesOrder.aspx?FromUI=TRUE&OrderType=SALES%20ORDER&OrderID=' + id.toString().split(" - ")[0] + "&CompanyCodeID=" + this.state.companycodeID;
                target = '_self';
            }

            if (column.indexOf("_external") != -1) {
                var target = '_blank';
            }

            var w = 1100;
            var h = 775;

            var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
            var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

            var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
            var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

            var left = ((width / 2) - (w / 2)) + dualScreenLeft;
            var top = ((height / 2) - (h / 2)) + dualScreenTop;

            return (
                windowopen(myURL, target, 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
            );

        }
    }
    descriptionLimiter = (preLimitString, descriptionLines) => {
        if (descriptionLines < 1)
        {
            return preLimitString
        }
        let limitedString = ""
        const stringLine = preLimitString.split('\n')
        let lineCount = 0
        stringLine.forEach(line => {
            if (lineCount < descriptionLines){
                if (limitedString.length){
                    limitedString = limitedString.concat('\n')
                }
                limitedString = limitedString.concat(line)
                lineCount += 1
            }
        });
        return limitedString
    }

    render() {
        let { order, orderBy, error, isLoaded, data, page, rowsPerPage, userinfo, filter, allowed, companycodeID, filterData, searchFilter, displaycolumns, is24SO, isPreview, plantID, showDetails, descriptionLines } = this.state;

        if (!data) data = [];
        data = dataSearch(data, searchFilter);
        if (!filterData) filterData = [];

        if (data.length <= page * rowsPerPage) {
            page = 0;
        }

        if (is24SO == "TRUE") {
            isPreview = false;
        }

        let defaultShowDetailsValue = (showDetails == true || showDetails == "true") ? true : false;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <LinearProgress />;
        } else if (allowed == "false") {
            return <Blank />;
        } else {
            return (
                <React.Fragment>
                    <Helmet title="Open Sales Orders" />
                    <Modal open={this.state.feedbackModalOpen} onClose={() => {this.handleCloseModal()}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={modalContainerStyle}>
                            <FeedbackModalComponent 
                                orderType="Sales Order" 
                                inputId={this.state.selectedItem?.salesorder || ""}
                                handleClose={this.handleCloseModal}
                                apiUrl={this.state.userinfo.apiurl}
                                companyInfo={
                                    {
                                        companyCodeID: this.state.companycodeID,
                                        plantID: this.state.plantID,
                                        apiKey: this.state.userinfo.apikey,
                                    }}
                            />
                        </Box>
                    </Modal>
                    <PageHeader
                        title="Open Sales Orders"
                        pageName={PageName}
                        refreshClick={this.refreshClick}
                        handleSearch={this.handleSearch}
                        companycodeID={this.state.isCustomerPortal ? undefined : companycodeID}
                        plantID={this.state.isCustomerPortal ? undefined : plantID}
                        showSetup={this.state.showSetup}
                        showDetails={defaultShowDetailsValue}
                        handleMenuChange={this.handleMenuChange}
                        disableEnter={this.state.isCustomerPortal}
                        userInfo={this.userInfo}
                    />

                    <Divider my={6} />

                    <Card pt={6}>
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={8}>
                                    <StatusPanel
                                        data={filterData}
                                        handleFilterChange={this.handleFilterChange}
                                        defaultValue={filter}
                                        isLoaded={isLoaded}
                                        userInfo={this.userInfo}
                                    />
                                </Grid>
                                {this.state.isCustomerPortal ?
                                    <></>  
                                    :
                                    <Grid item xs={4} align="right">
                                        {is24SO == "TRUE" || isPreview == true ? <NewButton buttonUrl={"../frmZSalesOrder.aspx?FromUI=TRUE&OrderType=SALES%20ORDER&CompanyCodeID=" + companycodeID} buttonName="New Sales Order" currentWindow={true} buttonStyle={buttonBlue} /> : <NewButton buttonUrl={"../frmSALES_DataEntry.aspx?OrderType=SALES%20ORDER&CompanyCodeID=" + companycodeID} buttonName="New Sales Order" buttonStyle={buttonBlue} />} <NewButton buttonUrl="../frmSALES_Search.aspx" buttonName="Search History" />
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                    <Paper>
                        {data.length > rowsPerPage ?
                            (<TablePagination
                                rowsPerPageOptions={[10, 20, 30, 50, 100, { value: -1, label: 'All' }]}
                                count={data.length}
                                component="div"
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "Previous Page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page"
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />) : null
                            }
                        <TableWrapper>
                        <Table>
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                    rows={displaycolumns}
                                    pageName={PageName}
                                />
                                <TableBody>
                                    {stableSort(dataSearch(data, searchFilter), getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, (page * rowsPerPage + rowsPerPage) < 0 ? 1000 : (page * rowsPerPage + rowsPerPage))
                                        .map((item,index) => {
                                            return (
                                                <TableRow key={index}>
                                                    {displaycolumns.map(
                                                        (column, idx) => (
                                                            <React.Fragment key={idx}>
                                                                {column.visible == "True" ?
                                                                    (<React.Fragment>
                                                                        {
                                                                            column.link == "True" && !this.state.isCustomerPortal ?
                                                                                (<TableCell style={linkColumnStyle} onClick={this.handleSOClick(item[column.id], column.id)} align={column.numeric === "True" ? 'right' : 'left'}>{renderHTML(item[column.id])}</TableCell>) 
                                                                                : 
                                                                                column.id.toLowerCase() === "action" ?
                                                                                <TableCell><Button onClick={() => this.handleFeedbackClick(item)} variant="contained" color="primary">Comment</Button></TableCell>
                                                                                : 
                                                                                (<TableCell align={column.numeric === "True" ? 'right' : 'left'}>{column.id.toLowerCase() === "description" ? renderHTML(this.descriptionLimiter(item[column.id], descriptionLines)) : renderHTML(item[column.id])}</TableCell>)
                                                                    } 
                                                                    </React.Fragment>) : null
                                                                }
                                                            </React.Fragment>
                                                                )
                                                    )}
                                                </TableRow>
                                            );
                                        })}

                            </TableBody>
                            </Table>
                        </TableWrapper>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 30, 50, 100, { value: -1, label: 'All' }]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "Previous Page"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Next Page"
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </React.Fragment>
            );
        }
    }
}

export default MyOpenOrders;
