import React from 'react'
import { pageHeaderStyle } from '../../components/Helper'
import { Typography } from '@material-ui/core'
interface propsType {

}

const ComingSoonComponent = (props:propsType) => {
    return (
        <>
            <Typography style={pageHeaderStyle} variant="h3" gutterBottom display="inline">
               Coming soon!
            </Typography>
        </>
    )
}

export default ComingSoonComponent