
import { Badge, Chip, FormControlLabel, Grid, Switch, Typography } from "@material-ui/core"
import { SmallButton, pageHeaderStyle } from "./Helper"
import {
    Settings as SettingsIcon,
} from "react-feather";
import React from "react"

interface PropsType {
    badge?: string
    isPreview?: boolean
    title: string
    handlePreviewClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    is24SO?: boolean
    showSetup?: boolean
    handleSetupOpen?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    showDetails?: boolean
    handleMenuChange?: (event: React.ChangeEvent<{}>, checked: boolean) => void
}
 const PageHeaderTitleComponent = (props: PropsType) => {
    return (
        <Grid item >
            {props.badge ?
                (<Badge badgeContent="New" color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} invisible={!props.isPreview} style={{ padding: '7px' }}>
                    <Typography style={pageHeaderStyle} variant="h3" gutterBottom display="inline">
                        {props.title} &nbsp;
                        {props.is24SO ? <Chip label={props.isPreview ? "Disable Preview" : "Enable Preview"} onClick={(event) => {props.handlePreviewClick && props.handlePreviewClick(event)}} size="small" style={props.isPreview ? { backgroundColor: '#dddddd' } : { backgroundColor: 'orange', color: '#FFF' }} /> : ""}
                    </Typography>
                </Badge>)
                : (<Typography style={pageHeaderStyle} variant="h3" gutterBottom display="inline">
                    {props.title}
                </Typography>)
            }
            {props.showSetup ?
                (<SmallButton size="small" onClick={(event) => {props.handleSetupOpen && props.handleSetupOpen(event)}} >
                    <SettingsIcon />
                </SmallButton>) : null
            }
            {props.showDetails == undefined ? null :
                (<FormControlLabel style={{paddingLeft: '6px'} } name="showDetails" control={<Switch />} label="Show Details" checked={props.showDetails || false} onChange={(event, checked) => { props.handleMenuChange && props.handleMenuChange(event, checked)}} />)
            }
        </Grid>
    )
}
export default PageHeaderTitleComponent