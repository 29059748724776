import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { darken } from "polished";

import {
  Badge,
  Grid,
  Hidden,
  InputBase,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Avatar,
  Typography,
  Box as MuiBox
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";
import { Divider } from '@material-ui/core';

import salesorders from "../pages/sales/salesorders"
import {
  Bell,
  MessageSquare,
  Search as SearchIcon,
    Power
} from "react-feather";

// images
//import profileImg from "../images/avatars/avatar-4.jpg";
import { browserHistory } from 'react-router';

import windowopen, {
    getApiURL,
    getApiKey,
    encodeComponent,
    getUserInfo,
    displayNameStyle,
    displayTitleStyle,
    ConfirmDialog
} from "./Helper";

import { NavLink as RouterNavLink, withRouter, Redirect } from "react-router-dom";

import brandImage from "../images/erp/masterplan_erp_logo.png";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};

    && {
        background-color: #F7F7F7;
        
        box-shadow: 1px 1px 1px ${props => props.theme.palette.grey[300]};
        height: 55px;
    }
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }

&& {
    color: ${props => props.theme.sidebar.header.background};
    :hover {
    background-color: ${props => darken(0.05, props.theme.header.background)};
  }
}
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${props => props.theme.header.indicator.background};
    color: ${props => props.theme.palette.common.white};
  }
`;

const Search = styled.div`
  border-radius: 2px;
  border: 1px solid #BCBEC0;
  background-color: ${props => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${props => darken(0.05, props.theme.header.background)};
  }

  ${props => props.theme.breakpoints.up("sm")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }

&& {
    color: ${props => props.theme.sidebar.header.background};
}
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${props => props.theme.header.search.color};
    padding-top: ${props => props.theme.spacing(2.5)}px;
    padding-right: ${props => props.theme.spacing(2.5)}px;
    padding-bottom: ${props => props.theme.spacing(2.5)}px;
    padding-left: ${props => props.theme.spacing(12)}px;
    width: 250px;
  }
`;

const StyledBadge = styled(Badge)`
  margin-right: ${props => props.theme.spacing(6)}px;

  span {
    background-color: ${props => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${props => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const Box = styled(MuiBox)`
    padding-top: 10px;
    text-align: center
`;

const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
));

class UserMenu extends Component {
  state = {
      anchorMenu: null,
      restoreDefault: false,
      userInfo: this.props.userInfo || getUserInfo()
  };
  

  toggleMenu = event => {
    this.setState({ anchorMenu: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorMenu: null });
    };

    featureRequestClicked = () => {
        var myURL = '../frmSHARED_FeatureRequest.aspx';

        var w = 1100;
        var h = 775;

        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;

        return (
            windowopen(myURL, '_blank', 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
        );
    };

    referBusinessClicked = () => {
        var myURL = '../frmSHARED_CustomerReferral.aspx';

        var w = 1100;
        var h = 775;

        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;

        return (
            windowopen(myURL, '_blank', 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
        );
    };

    whatsNewClicked = () => {
        var myURL = '../frmSYSTEM_ReleaseNotes.aspx?ReleaseType=ALL';

        var w = 1100;
        var h = 775;

        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;

        return (
            windowopen(myURL, '_blank', 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
        );
    };

    myInfoClicked = () => {
        var myURL = '../frmSYSTEM_DashboardOptions.aspx';

        var w = 1100;
        var h = 775;

        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;

        return (
            windowopen(myURL, '_blank', 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
        );
    };

    trainingCenterClicked = () => {
        var myURL = '../frmSYSTEM_TrainingCenter.aspx';

        var w = 1100;
        var h = 775;

        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;

        return (
            windowopen(myURL, '_blank', 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
        );
    };
 
       
 
    adminPortalClicked = () => {
        const url = "../frmADMIN_Main.aspx";
        window.location.href = url;
    }


    systemSetupClicked = () => {

        const requestOptions = {
            method: 'get',
            headers: {
                'Content-Type': 'Text'
            }
        };

        //const url = "../frmSYSTEM_Ping.aspx?ApiKey=" + getApiKey();

        var apikey = encodeComponent(getApiKey());
        const url = "../frmSYSTEM_Ping.aspx?APIKey=" + apikey;

        fetch(url, requestOptions)
            .then(res => res.text())
            .then(
                (result) => {
                    if (result.toString().indexOf("TRUE") == 0)
                    {
                        var myURL = '../frmSYSTEM_Setup.aspx';

                        var w = 1100;
                        var h = 775;

                        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
                        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

                        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
                        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

                        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
                        var top = ((height / 2) - (h / 2)) + dualScreenTop;

                        return (
                            windowopen(myURL, '_blank', 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
                        );
                    }
                    else
                    {
                        window.location.href = "../../frmLogOut.aspx";
                    }
                },
                (error) => {
                    alert("error");
                }
            )



    };

    signOutClicked = () => {
        sessionStorage.clear();
        window.location.href = '../../frmLogOut.aspx';
    };

    restoreScreenDefaultsClicked = () => {
        this.setState({ restoreDefault: !this.state.restoreDefault });
    };

    clearScreenSettings = () => {
        sessionStorage.clear();
        window.location.href = '../';
    };

    render = () => {
    const { anchorMenu, restoreDefault } = this.state;
    const open = Boolean(anchorMenu);

    return (
        <React.Fragment >
            <IconButton
                aria-owns={open ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.toggleMenu}
                color="inherit"
            >
            <StyledBadge
                overlap="circular"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                variant="dot"
            >
                    <Avatar src="../frmREPORTS_EmployeeImage.aspx?CurrentEmployee=TRUE" />
                </StyledBadge>
            </IconButton>
            <Menu
            id="menu-appbar"
            anchorEl={anchorMenu}
            open={open}
            onClose={this.closeMenu}
                >
                    <MenuItem
                        onClick={() => {
                            this.featureRequestClicked();
                        }}
                    >
                        Feature Request
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            this.referBusinessClicked();
                        }}
                    >
                        Refer a Business
                    </MenuItem>
                    {(!this.state.userInfo || !this.state.userInfo.iscustomer || this.state.userInfo.iscustomer === "False") ? 
                    <MenuItem
                        onClick={() => {
                            this.whatsNewClicked();
                        }}
                    >
                        What's New?
                    </MenuItem>
                    : <></>}
                    <Divider />
                    {this.state.userInfo !== undefined && this.state.userInfo.showportal !== undefined && this.state.userInfo.showportal === "89" &&
                        <MenuItem
                            onClick={() => {
                                this.adminPortalClicked();
                            }}
                        >
                            Admin Portal
                        </MenuItem>
                    }
                    {this.state.userInfo !== undefined && this.state.userInfo.showportal !== undefined && this.state.userInfo.showportal === "89" &&
                        <Divider />
                    }
                    { (!this.state.userInfo || !this.state.userInfo.iscustomer || this.state.userInfo.iscustomer === "False") &&
                        <MenuItem
                                    onClick={() => {
                                        this.myInfoClicked();
                                    }}
                                >
                                    My Info
                        </MenuItem>
                    }
                    { (!this.state.userInfo || !this.state.userInfo.iscustomer || this.state.userInfo.iscustomer === "False") &&
                        <MenuItem
                            onClick={() => {
                                this.trainingCenterClicked();
                            }}
                        >
                            Training Center
                        </MenuItem>
                    }
                    { (!this.state.userInfo || !this.state.userInfo.iscustomer || this.state.userInfo.iscustomer === "False") &&
                        <MenuItem
                            onClick={() => {
                                this.systemSetupClicked();
                            }}
                        >
                            System Setup
                        </MenuItem>
                    }
                    { (!this.state.userInfo || !this.state.userInfo.iscustomer || this.state.userInfo.iscustomer === "False") &&
                        <MenuItem
                            onClick={() => {
                                this.restoreScreenDefaultsClicked();
                            }}
                        >
                            <ConfirmDialog
                                handleConfirm={this.clearScreenSettings}
                                title="Restore Screen Defaults"
                                description="This will remove all screen defaults including saved filters and searches. Would you like to continue?"
                                open={restoreDefault}
                            />
                        </MenuItem>
                    }
                <Divider />
            <MenuItem
                        onClick={() => { this.signOutClicked(); }}
            >
                Sign Out
            </MenuItem>
            </Menu>
        </React.Fragment>
    );
  }
}

class Header extends React.Component {
    state = {
        value: "",
        isLoaded: false,
        error: null,
        userInfo: this.props.userInfo || getUserInfo()
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.setState({ value: e.target.value });
        }
    };

    fetchData = () => {
        const requestOptions = {
            method: 'get',
            headers: {
                'Content-Type': 'Text'
            }
        };

        //const url = "../frmSYSTEM_Ping.aspx?ApiKey=" + getApiKey();
        var apikey = encodeComponent(getApiKey());
        const url = "../frmSYSTEM_Ping.aspx?APIKey=" + apikey;

        if (getApiURL().indexOf("localhost") != -1) {
            this.setState({
                isLoaded: true,
                error: null
            });

            return true;
        }

        fetch(url, requestOptions)
            .then(res => res.text())
            .then(
                (result) => {
                    if (result.toString().indexOf("TRUE") == 0) {
                        this.setState({
                            isLoaded: true,
                            error: null
                        });
                    }
                    else {
                        this.setState({
                            isLoaded: true,
                            error: true
                        });
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    componentDidMount = () => {
        //UNCOMMENT FOR PRODUCTION
        this.fetchData();
    }

    render = () => {
        const { onDrawerToggle } = this.props;
        const { value, error } = this.state;

        if (error) {
            window.location.href = '../../frmLogOut.aspx';
        } else {
            return (
                <React.Fragment>
                    <AppBar position="sticky" elevation={0} className="print-hidden">
                        <Toolbar>
                            <Grid container alignItems="center">
                                <Hidden mdUp>
                                    <Grid item>
                                        <IconButton
                                            color="inherit"
                                            aria-label="Open drawer"
                                            onClick={onDrawerToggle}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    </Grid>
                                </Hidden>
                                {
                                    this.state.userInfo.iscustomer && this.state.userInfo.iscustomer === "True" ? 
                                    <></>
                                    :
                                    <Grid item>
                                        <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon />
                                            </SearchIconWrapper>
                                            <Input placeholder="Global Search ..." onKeyDown={this.handleKeyDown} />
                                        </Search>
                                    </Grid>
                                }
                                <Grid item xs>
                                    {/*<Box ml={1} alignItems="center" alignContent="center">
                                        <a href="/">
                                            <img width="180px" src={brandImage}></img>
                                        </a>
                                    </Box>*/}
                                </Grid>
                                <Grid item>
                                    <Typography style={displayNameStyle} display="inline">
                                        {this.state.userInfo.displayname} &nbsp;
                                    </Typography>
                                    { this.state.userInfo.title ? <React.Fragment><br />
                                    <Typography style={displayTitleStyle} display="inline">
                                        {this.state.userInfo.title} &nbsp;
                                    </Typography></React.Fragment>: null}
                                </Grid>
                                <Grid item>
                                    <UserMenu userInfo={this.state.userInfo}/>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    {value !== "" ? <Redirect to={{ pathname: '/system_search', state: { filter: this.state.value } }} component={NavLink} /> : null}
                </React.Fragment>
            );
        }
    }
}

export default connect()(withTheme(Header));
