import React from "react";
import styled from "styled-components";
import Helmet from 'react-helmet';

import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import * as Icon from "@material-ui/icons";

import {
    pageHeaderStyle,
} from "../../components/Helper";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function EmptyCard() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="body2" gutterBottom>
          You do not have access to this page.
        </Typography>
      </CardContent>
    </Card>
  );
}

function Blank() {
  return (
    <React.Fragment>
          <Helmet title="Access Denied" />
      <Typography style={pageHeaderStyle} variant="h3" gutterBottom display="inline">
              <Icon.Warning /> Access Denied.
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Blank;
