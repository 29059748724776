import React, { Component } from "react";
import styled from "styled-components";
import { rgba } from "polished";

import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import { darken } from "polished";

import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import {
  Avatar,
  Badge,
  Box as MuiBox,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  Grid,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  IconButton as MuiIconButton,
  Menu,
    MenuItem,
    Tooltip,
  Button
} from "@material-ui/core";

import { Add, Remove} from "@material-ui/icons";

import routes from "../routes/index";

// images
import brandImage from "../images/erp/masterplan_erp_logo.png";
import brandImageNO from "../images/erp/24sevenoffice_masterplan_erp_logo.png";
import usFlag from "../images/flags/us.png";
import noFlag from "../images/flags/no.png";
import caFlag from "../images/flags/ca.png";
import { ExternalLink } from "react-feather";

import windowopen, {
    getDefaultCompanyCodeID,
    getApiURL,
    getCompanyCodes,
    getCompanyCodeName,
    getUserInfo,
    getApiKey,
    stableSort,
    getSorting,
    dataSearch,
    linkColumnStyle,
    errorColumnStyle,
    writeDebugLine,
    getSelectedCompanyCodeID
} from "../components/Helper";

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const Box = styled(MuiBox)`
    padding-top: 15px;
`;

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: #2D3B49;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  width: 239px;
`;

const List = styled(MuiList)`
  background-color: #2D3B49;
`;

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(0)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: #F7F7F7;
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 55px;
  max-height: 55px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px;
  &&{width: 239px;}

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 55px;
    max-height: 55px;
  }
`;

const Category = styled(ListItem)`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  font-size: ${props => props.theme.typography.h2.fontSize};
  min-height: 35px;
  max-height: 50px;
  
  
  && {
    border-left: 3px solid #2D3B49;
    border-bottom: 1px solid #25313C;
}

  svg {
    color: #FFFFFF;
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 1;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
    border-left: 3px solid #229BFF;
  }

  &.${props => props.activeClassName} {
    background-color: #25313C;
    border-left: 3px solid #FFB155;

    span {
      color: #FFB155;
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0px;
  min-height: 45px;
  max-height: 65px;
  span {
    color: #FFFFFF;
    font-family: ${props => props.theme.typography.sidebar.fontFamily};
    font-size: ${props => props.theme.typography.sidebar.fontSize}px;
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    padding: 0px 0px 0px ${props => props.theme.spacing(0)}px;
  }
&:hover span {
    color: #229BFF;
  }
}
`;

const CategorySecondaryText = styled(Typography)`
  min-height: 20px;
  max-height: 20px;
  color: #C4C4C4;
  padding: 0px 0px 0px ${props => props.theme.spacing(4)}px;
 && {
  font-family: ${props => props.theme.typography.sidebar.fontFamily};
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
}
}
`;

const CategoryIconLess = styled(Remove)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(Add)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  && {
    border-left: 3px solid #25313C;
    background-color: #25313C;
}

  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: #229BFF;
    svg {
            color: #229BFF;
        }
  }
  
  &&:hover {
    border-left: 3px solid #229BFF;
    background-color: #25313C;
    }

  &.${props => props.activeClassName} {
    background-color: #25313C;
    border-left: 3px solid #FFB155;

    span {
      color: #FFB155;
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    padding-left: ${props => props.theme.spacing(6)}px;
    font-family: ${props => props.theme.typography.sidebar.fontFamily};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    color: #FFFFFF;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarSubSection = styled(Typography)`
  color: #ffee55;
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
&& {
    border-left: 3px solid #25313C;
    background-color: #25313C;
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    font-family: ${props => props.theme.typography.sidebar.fontFamily};
    font-size: ${props => props.theme.typography.h1.fontSize}px;
}
`;

const SidebarFooter = styled.div`
  background-color: #2D3B49 !important;
  padding: ${props => props.theme.spacing(2.75)}px
    ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  &&{width: 239px;}
`;

const SidebarFooterText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(1)}px;
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
  font-size: .725rem;
  display: block;
  padding: 1px;
`;

const StyledBadge = styled(Badge)`
  margin-right: ${props => props.theme.spacing(1)}px;

  span {
    background-color: ${props => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${props => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 20px;
    height: 20px;
    color: #FFFFFF;
  }

   && {
    padding: 5px;
}
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
    badge,
    description,
    enabled,
    tooltip,
    is24SO,
  ...rest
}) {
    return (
    <Category {...rest}>
            {icon}
            <Tooltip title={tooltip} placement="right" arrow><CategoryText primaryTypographyProps={{display:'inline'}}>{name}
                {/*{
                    isCollapsable ? (
                        isOpen ? (
                            <CategorySecondaryText>{description}</CategorySecondaryText>
                        ) : null
                    ) : null
                }*/}
                </CategoryText></Tooltip>
      {isCollapsable ? (
              isOpen ? (
              <CategoryIconMore />
          ) : (
              <CategoryIconLess />
        )
      ) : null}
          {badge ? <CategoryBadge label={badge} /> : ""}
            </Category>
  );
}

class SidebarLink extends React.Component {
    ReportClick(e, categoryid) {
        e.preventDefault();

        var myURL = '../frmREPORTS_ReportCenter.aspx?ReportCategoryID=' + categoryid;

            var w = 1100;
            var h = 775;

            var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
            var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

            var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
            var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

            var left = ((width / 2) - (w / 2)) + dualScreenLeft;
            var top = ((height / 2) - (h / 2)) + dualScreenTop;

            return (
                windowopen(myURL, '_blank', 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=1100,height=775,left=' + left + ',top=' + top + ',modal=yes')
            );
    }

    ExternalClick(e, externalpath, fullScreen) {
        e.preventDefault();

        var myURL = externalpath;

        var w = 1100;
        var h = 775;

        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;

        if (fullScreen) {
            w = window.screen.width;
            h = window.screen.height;

            left = 0;
            top = 0;
        }

        return (
            windowopen(myURL, '_blank', 'toolbar=no,status=no,resizable=1,scrollbars=1,menubar=no,location=no,width=' + w + ',height=' + h + ',left=' + left + ',top=' + top + ',modal=yes,fullscreen=yes')
        );
    }

    render() {
        const { name, to, badge, tooltip, categoryid, icon, externalpath, fullScreen, enabled, keyIdx } = this.props;
        if (categoryid) {
            return (
                <Link
                    button
                    dense
                    component={NavLink}
                    exact
                    to={to}
                    onClick={(e) => this.ReportClick(e, categoryid)}
                    key={categoryid}
                >
                    <Tooltip title={tooltip} placement="right" arrow><LinkText>{name}</LinkText></Tooltip>
                    {badge ? <LinkBadge label={badge} /> : ""}
                    {icon ? <IconButton>{icon}</IconButton> : ""}
                </Link >
            );
        }
        else if (externalpath) {
            return (
                <Link
                    button
                    dense
                    component={NavLink}
                    exact
                    to={to}
                    onClick={(e) => this.ExternalClick(e, externalpath, fullScreen)}
                    key={keyIdx}
                >
                    <Tooltip title={tooltip} placement="right" arrow><LinkText>{name}</LinkText></Tooltip>
                    {badge ? <LinkBadge label={badge} /> : ""}
                    {icon ? <IconButton>{icon}</IconButton> : ""}
                </Link >
            );
        }
        else if (enabled) {
            return (
                <Link
                    button
                    dense
                    component={NavLink}
                    exact
                    to={to}
                    activeClassName="active"
                >
                    <Tooltip title={tooltip} placement="right" arrow><LinkText>{name}</LinkText></Tooltip>
                    {badge ? <LinkBadge label={badge} /> : ""}
                </Link>
            );
        }
        else {
            return null;
        }
    }
}

class LanguageMenu extends Component {
    state = {
        anchorMenu: null
    };

    toggleMenu = event => {
        this.setState({ anchorMenu: event.currentTarget });
    };

    closeMenu = () => {
        this.setState({ anchorMenu: null });
    };

    render = () => {
        const { anchorMenu } = this.state;
        const open = Boolean(anchorMenu);
        var flag = usFlag;

        var language = window.location.href;
        if (language != null && language != "") {
            language = language.toLowerCase();
            if (language.indexOf("masterplan.24sevenoffice.com") != -1) {
                flag = noFlag;
            }
            else if (language.indexOf("na2") != -1) {
                flag = caFlag;
            }
        }

        return (
            <React.Fragment>
                <IconButton
                    aria-owns={open ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={this.toggleMenu}
                    color="inherit"
                >
                    <Flag src={flag} alt="English" />
                </IconButton>
            </React.Fragment>
        );
    }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: props.userinfo,
      controls: [],
      language: "en-US",
      rcCode: "",

    };
  }

    toggle = index => {
        // Collapse all elements
        const controls = this.state.controls;
        controls.forEach(
            (item, itemIndex) => {
                if (itemIndex !== index )
                    controls[itemIndex] = false;
            }
        );
        
        // Toggle selected element
        controls[index] = !controls[index];
        this.setState({
            controls: controls
        });
    };

    UNSAFE_componentWillMount() {
        /* Open collapse element that matches current url */
        const pathName = this.props.location.pathname;

        routes.forEach((route, index) => {
            const isActive = pathName.indexOf(route.path) === 0;
            const isOpen = route.open;
            const isHome = route.containsHome && pathName === "/" ? true : false;

            var controlArray = this.state.controls;
            controlArray[index] = isActive || isOpen || isHome;
            this.setState({
                controls: controlArray
            });
        });
    }

    componentDidMount() {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "ApiKey": getApiKey(),
                "CompanyCodeID": Number(getSelectedCompanyCodeID(this.state.userInfo))
            })
        };
        fetch(getApiURL() + "DashboardRoutes", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    var allowedCategories = JSON.stringify(result.data);
                    var is24SO = result.is24SO;
                    var rcCode = result.ReactClientCode
                    this.setState({
                        rcCode: rcCode
                    });
                    // ********** DEBUG **********
                    writeDebugLine(allowedCategories);
                    // ********** END DEBUG **********
                    allowedCategories = allowedCategories.replace(/"/g, '');
                    var categoriesArray = allowedCategories.split(",");
                    var controlArray = this.state.controls;
                    const pathName = this.props.location.pathname;
                    var openFirst = false;
                    if (pathName === "/") {
                        openFirst = true;
                    }
                    routes.forEach((route, index) => {
                        categoriesArray.forEach((category, idx) => {
                            if (category.toLowerCase() == route.subcategory.toLowerCase()) {
                                route.enabled = true;
                                if (openFirst && (route.subcategory === "InventoryEdition" || route.subcategory=== "CustomerPortal")) {
                                    controlArray[index] = true;
                                    openFirst = false;
                                }
                            }
                        });
                    });

                    if (pathName === "/") {
                        this.setState({
                            language: result.Language,
                            controls: controlArray
                        });
                    } else {
                        this.setState({
                            language: result.Language
                        });
                    }
                },
                (error) => {
                    console.log('error');
                }
            )
    }

  render() {
      const { classes, staticContext, ...other } = this.props;
      let language = this.state.language;
      let rcCode = this.state.rcCode;
      var languageUrl = window.location.href;
      if (languageUrl != null && languageUrl != "") {
          languageUrl = languageUrl.toLowerCase();
          if (languageUrl.indexOf("masterplan.24sevenoffice.com") != -1) {
              language = "nb-no";
          }
          else if (languageUrl.indexOf("na2") != -1) {
              language = "en-ca";
          }
      }

    return (
      <Drawer variant="permanent" {...other}>
       <Brand>
                <Box ml={1}><a href="/">
                    <img width="160px" src={brandImage}></img>
                </a></Box>
        </Brand>
        <Scrollbar>
          <List disablePadding>
            <Items>
                {routes.map((category, index) => (
                    <React.Fragment key={index}>
                            {category.header ? (
                                <SidebarSection>{category.header}</SidebarSection>
                        ) : null}

                            {category.enabled ? (category.children ? (
                                <React.Fragment key={index}>
                                    <SidebarCategory
                                    isOpen={!this.state.controls[index]}
                                    isCollapsable={true}
                                    name={category.id}
                                    icon={category.icon}
                                    button={true}
                                    onClick={() => this.toggle(index)}
                                    description={category.description}
                                    enabled={category.enabled}
                                    tooltip={category.tooltip}
                                    is24SO={category.is24SO}
                                    />

                                    <Collapse
                                        in={this.state.controls[index]}
                                        timeout="auto"
                                        unmountOnExit
                                >
                                    {category.children.map((route, index) => {
                                        return (
                                            (
                                                route.subcategory ?
                                                    <SidebarSubSection key={index}>{route.name}</SidebarSubSection>
                                                    :
                                                    (route.rcCode === null || typeof route.rcCode === 'undefined' || route.rcCode.split("|").includes(rcCode)) &&
                                                    <SidebarLink
                                                        key={index}
                                                        name={route.name}
                                                        to={route.path}
                                                        icon={route.icon}
                                                        badge={route.badge}
                                                        tooltip={route.tooltip}
                                                        categoryid={route.categoryid}
                                                        externalpath={route.externalpath}
                                                        fullScreen={route.fullscreen}
                                                        enabled={route.enabled}
                                                        keyIdx={index}
                                                    />
                                            )
                                        );
                                    })}
                                    <SidebarSubSection></SidebarSubSection>
                                    <SidebarSubSection></SidebarSubSection>
                                    </Collapse>
                                </React.Fragment>
                        ) : (
                                    <SidebarCategory
                                    isCollapsable={false}
                                    name={category.id}
                                    to={category.path}
                                    activeClassName="active"
                                    component={NavLink}
                                    icon={category.icon}
                                    exact
                                    badge={category.badge}
                                    enabled={category.enabled}
                                    tooltip={category.tooltip}
                                    is24SO={category.is24SO}
                                    /> 
                                ) ) : null }
                        </React.Fragment>
              ))}
            </Items>
          </List>
            </Scrollbar>
            <SidebarFooter>
                <Grid container spacing={2}>
                    <Grid item>
                        <LanguageMenu Language={language}/>
                    </Grid>
                </Grid>
            </SidebarFooter>
      </Drawer>
    );
  }
}

export default withRouter(Sidebar);
